import React, { FunctionComponent, CSSProperties, useEffect } from "react";

interface AdUnit {
  className?: string;
  containerStyle?: CSSProperties;
  adSlot: string;
}

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const AdUnit: FunctionComponent<AdUnit> = props => {
  const { containerStyle, adSlot } = props;

  // componentDidMount
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div style={containerStyle}>
      {/* <!-- RentaliosResultsReact --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "100%", height: "100px" }}
        data-ad-client="ca-pub-4787561474704110"
        data-ad-slot={adSlot}
      ></ins>
    </div>
  );
};

export default AdUnit;
