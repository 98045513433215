import React from "react";

function Rectangle({
  fill = "#D0D0D0",
  width = "13px",
  height = "13px",
  style = null
}: {
  fill?: string;
  width?: string;
  height?: string;
  style?: any;
}) {
  return (
    <svg style={style} height={height} width={width}>
      <rect width={width} height={height} fill={fill} />
    </svg>
  );
}

export default Rectangle;
