import React from "react";
import homeBg from "../assets/images/homeBgv2.png";
import { BLACK_COLOR } from "../constants";

const backgroundStyle = {
  backgroundImage: `url("${homeBg}")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "100vh"
};

const backgroundStyle_black = {
  background: BLACK_COLOR,
  height: "100%"
};

function Background(props: {
  isBackgroundActive: boolean;
  children: React.ReactNode;
}) {
  return (
    <div style={props.isBackgroundActive ? backgroundStyle : backgroundStyle_black}>
      {props.children}
    </div>
  );
}

export default Background;
