import React from "react";
import styled from "styled-components";
import { BLACK_COLOR } from "../constants";
import SearchIcon from "./icons/SearchIcon";
import CrowdIndex from "./icons/CrowdIndex";
import heartEmpty from "../assets/images/heartEmpty.svg";
import feedBackIcon from "../assets/images/feedback-icon.svg";
import BellIcon from "./icons/BellIcon";
import { useHistory, useLocation } from "react-router-dom";

const StyledSideNav = styled.div`
  position: absolute;
  height: 100%;
  width: 75px;
  z-index: 1;
  //   top: 3.4em;
  background-color: ${BLACK_COLOR};
  overflow-x: hidden;
  padding-top: 10px;
  // border-right: solid 0.5px rgba(255,255,255,0.6)
`;

const StyledNavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 75px;
  text-align: center;
  margin: auto;
  font-size: 10px;
  padding: 0 10px 10px;
  color: rgba(255, 255, 255, 0.6);
`;

function SideBar() {
  const history = useHistory();

  const onSearchClick = async (e: any): Promise<void> => {
    window.open("https://www.crowdalerts.com", "_self");
    
  };

  const onLevelIndexClick = async (e: any): Promise<void> => {
    history.push("/crowd_index");
  };

  const onSavedPlacesClick = async (e: any): Promise<void> => {
    history.push("/savedPlaces");
  };

  const onFeedbackClick = async (e: any): Promise<void> => {
    history.push("/feedback");
  };
  const onAlertsClick = async (e: any): Promise<void> => {
    history.push("/alerts");
  };
  return (
    <StyledSideNav style={{ cursor: "pointer" }}>
      <StyledNavItem onClick={onSearchClick}>
        <SearchIcon width="20px" height="20px" />
        Search
      </StyledNavItem>
      <StyledNavItem onClick={onLevelIndexClick}>
        <CrowdIndex width="20px" height="20px" />
        Crowd Level Index
      </StyledNavItem>
      <StyledNavItem onClick={onSavedPlacesClick}>
        <img src={heartEmpty} alt="Empty Heart" />
        Saved Places
      </StyledNavItem>
      <StyledNavItem onClick={onAlertsClick}>
        <BellIcon width="20px" height="20px" onClick={(e)=>{}} />
        Alerts
      </StyledNavItem>
      <StyledNavItem onClick={onFeedbackClick}>
        <img src={feedBackIcon} />
        Feedback
      </StyledNavItem>
    </StyledSideNav>
  );
}

export default SideBar;
