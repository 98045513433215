import React, { useState } from "react";
import { Row, Col, Carousel, Form } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { NO_INFO } from "../constants";
import NoInfoWarn from "../assets/images/NoInfoWarning.png";
import Rectangle from "./icons/Rectangle"

const selectStyle = {
	fontSize: '13px', 
	height: '25px', 
	width: '110px',
	background: 'none',
	border: 'none',
	color: 'white',
	padding: '0 10px'
}

function CrowdCharts(props: {
  actual: any;
  times: any;
	today: any;
	range: any
}) {
  const { actual, times, today } = props;
  const [state, setState] = useState<{
    today: number;
    crowd: number;
  }>({
    today: today.getDay()-1,
    crowd: 0,
  });

  if (times) {
    const handleRerender = () => { setState({...state}) }

    const handleDayChange = (select:any) => {
      let day:number = select.target.value
      setState({...state, today: day})
    }
    
    let dailyPopulation = times
    let higherActual: boolean;
    // eslint-disable-next-line
    dailyPopulation.map((day:any, i:any) => {
      let times1: any = {
        labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
        datasets: [
          {
            label: 'Current', backgroundColor: '#D2D3D6',
            borderWidth: 15, data: [0,0,0,0,0,0,0,0,0,0,0,0]
          },
          {
            label: 'Predicted', backgroundColor: '#F6A02E',
            borderWidth: 15, data: [0,0,0,0,0,0,0,0,0,0,0,0]
          },
          {
            label: 'Population', backgroundColor: '#4389C3',
            borderWidth: 15, data: []
          },
        ]
      };
      let times2: any = {
        labels: ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        datasets: [
          {
            label: 'Current', backgroundColor: '#F6A02E',
            borderWidth: 15, data: [0,0,0,0,0,0,0,0,0,0,0,0]
          },
          {
            label: 'Predicted', backgroundColor: '#D2D3D6',
            borderWidth: 15, data: [0,0,0,0,0,0,0,0,0,0,0,0]
          },
          {
            label: 'Population', backgroundColor: '#4389C3',
            borderWidth: 15, data: []
          },
        ]
      };
      // eslint-disable-next-line
      day.data.map((population:any, j:any) => {
        if (j >= 0 && j <= 11) {
          times1.datasets[2].data.push(population)
        }
        if (j >= 12 && j <= 23) {
          times2.datasets[2].data.push(population)
        }
      })
      if (today.getDay()-1 === i) {
        if (today.getHours() < 12) {
          times1.datasets[0].data[today.getHours()] = actual
          times1.datasets[1].data[today.getHours()] = day.data[today.getHours()]
        } else {
          times2.datasets[0].data[today.getHours()-12] = actual
          times2.datasets[1].data[today.getHours()-12] = day.data[today.getHours()]
        }
        higherActual = actual > day.data[today.getHours()]? true: false
      }
      if (higherActual) {
        let temp = times1.datasets[0]
        times1.datasets[0] = times1.datasets[1]
        times1.datasets[1] = temp
        temp = times2.datasets[0]
        times2.datasets[0] = times2.datasets[1]
        times2.datasets[1] = temp
      }
      day.chartData = [times2, times1]
    })
    // console.log(dailyPopulation)

    return (
      <div style={{marginBottom:'20px'}}>
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Row>
              <Col xs={6} style={{fontSize: '14px', textAlign: 'right'}}>
                <Form.Label>Usual Crowd Levels</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Control as="select" style={selectStyle}
                  onChange={handleDayChange} defaultValue={today.getDay()-1}
                  >
                  {times.map((day:any, i:number) => {
                    return (
                      <option key={i} value={i} style={{backgroundColor: '#515151'}}>
                        {day.name}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Form>
        {dailyPopulation.map((day:any, i:any) => {
          let display = (i === state.today)? 'block': 'none'
          return (
            <Carousel key={i} indicators={false} style={{display: display}}
              interval={null}
              onSlide={handleRerender}>
              {day.chartData.map((times:any, j:any) => {
                return (
                  <Carousel.Item key={j}>
                    <Bar
                      data={times}
                      width={100}
                      height={150}
                      redraw={true}
                      options={{ 
                        animation: { duration: 0 },
                        maintainAspectRatio: false,
                        legend: { 
                          display: false, position: 'bottom',
                          labels: {
                            fontSize: 12, fontColor: '#FFF',
                            boxWidth: 13, padding: 15,
                            filter: function(legendItem: any, times: any) {
                              return legendItem.index !== 2
                            }
                          }
                        },
                        scales: {
                          xAxes: [
                            {
                              gridLines: { display: false },
                              ticks: {
                                fontColor: "#AFAFAF"
                              },
                              stacked: true
                            }
                          ],
                          yAxes: [
                            {
                              stacked: false,
                              gridLines: {
                                display: true,
                                drawTicks: false,
                                color: "#AFAFAF",
                                zeroLineColor: '#AFAFAF'
                              },
                              ticks: {
                                fontColor: "#AFAFAF",
                                padding: 5
                              },
                              display: true,
                              offsetGridLines: false
                            }
                          ]
                        }
                      }}
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          )
        })}
        <div style={{ fontSize: "12px", color: "#FFF", textAlign: "center" }}>
          <span style={{ marginRight: "5px" }}>
            <Rectangle style={{ marginBottom: "2px" }} fill="#D2D3D6" />
          </span>
          <span style={{ marginRight: "15px" }}>Predicted</span>
          <span style={{ marginRight: "5px" }}>
            <Rectangle style={{ marginBottom: "2px" }} fill="#F6A02E" />
          </span>
          <span style={{ marginRight: "5px" }}>Current</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className='no-times-container'>
        <div style={{display: 'flex', alignItems: 'center', 
            flexDirection: 'column', justifyContent: 'center'}}>
          <img
            style={{ width: "40px", height: "40px", marginBottom: '10px' }}
            src={NoInfoWarn}
            alt="No Information Warning"
          />
          {NO_INFO}
        </div>
      </div>
    );
  }
}

export default CrowdCharts;
