import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { isEmpty as _isEmpty } from "lodash";
import { usePlaceState } from "../store/place/placeContext";
import { useResultsState } from "../store/results/resultsContext";
import PlaceInput from "../components/PlaceInput";
import MapWrapper from "../components/Map/MapWrapper";
import EstimatedCrowdDetails from "../components/EstimatedCrowdDetails";
import { listContainVenue, removeVenueFromList } from "../utilities/utilities";
import { crowdAnalytics2saved } from "../utilities/convertVenues";
import {
  makeAndHandleHistorical,
  makeAndHandleResultsRequest
} from "../utilities/serviceCalls";
import { RecentPlacesContext } from "../contexts";
import loader from "../assets/images/loader.gif";
import Lockr from "lockr";
import { LocalStorage } from "../constants";
import AdUnit from "../components/AdUnit";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VenueDetails() {
  const { placeState, dispatchPlaceState } = usePlaceState();
  const { resultsState, dispatchResultsState } = useResultsState();
  const [recentPlaces, setRecentPlaces] = React.useContext(RecentPlacesContext);
  const [recall, setRecall] = useState(true);
  const query = useQuery();
  let id = query.get("venueId");
  let lat = query.get("lat");
  let lng = query.get("lng");
  let name = query.get("name");
  // let searchQuery = query.get("query");
  let details; //, distance;

  const executeResultsEstimation = async (
    id: any,
    latitude: any,
    longitude: any,
    name: any
  ): Promise<void> => {
    let results;

    // Check if selectedVenue ID is an Address and handle appropriate results request
    results = await makeAndHandleResultsRequest({
      id: id,
      location: { lat: latitude, lng: longitude },
      name: name
    });

    dispatchResultsState({
      type: "SET_VENUES",
      payload: results
    });

    // replace selected venue
    dispatchPlaceState({
      type: "SET_SELECTED_VENUE",
      payload: results.venue
    });

    const recentPlace = crowdAnalytics2saved(results.venue);
    if (!listContainVenue(recentPlaces, recentPlace)) {
      Lockr.sadd(LocalStorage.RECENTPLACES, recentPlace);
      const newRecentPlaces = Lockr.smembers(LocalStorage.RECENTPLACES);
      setRecentPlaces(newRecentPlaces); // Update global state
    } else {
      // Already Exist in List, push it to latest
      let newRecentPlaces = removeVenueFromList(recentPlaces, recentPlace);
      newRecentPlaces.push(recentPlace);
      Lockr.set(LocalStorage.RECENTPLACES, newRecentPlaces);
      setRecentPlaces(newRecentPlaces); // Update global state
    }

    let lat = results.venue.location.lat;
    let lng = results.venue.location.lng;

    results = await makeAndHandleHistorical({
      latitude: lat,
      longitude: lng,
      radius: 5000
    });
    if (results !== undefined) {
      let markers: any = [];
      results.data.forEach((historical: any) => {
        markers.push({
          name: historical.name,
          lat: historical.latitude,
          lng: historical.longitude,
          index: historical.index_level
        });
      });
      dispatchPlaceState({
        type: "SET_MARKERS",
        payload: markers
      });
    }
  };

  if (id && recall) {
    setRecall(false);
    executeResultsEstimation(id, lat, lng, name);
  }

  if (resultsState.loading) {
    details = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <img src={loader} alt="" width="100px" height="100px" />
      </div>
    );
  } else {
    details = (
      <EstimatedCrowdDetails
        className="mt-1 shadow p-3 mb-5"
        crowd={resultsState.venues}
        venue={placeState.selectedVenue}
      />
    );
  }

  //console.log(placeState.selectedVenue)
  return (
    <Fragment>
      <Row
        className="pt-5"
        style={{
          minHeight: "100vh",
          borderLeft: "solid 0.5px rgba(255,255,255,0.6)"
        }}
      >
        <Col xs={12} lg={6}>
          <PlaceInput buttonText ="Estimate"  height={"30px"} margin={10}/>
          {!_isEmpty(resultsState.venues) &&
            !_isEmpty(placeState.selectedVenue) && (
              <Fragment>{details}</Fragment>
            )}
          <div style={{ height: "100px" }}>
            <AdUnit containerStyle={{ height: "100px" }} adSlot="5988687107" />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          {!_isEmpty(placeState.selectedVenue) &&
            !_isEmpty(resultsState.venues) && (
              <MapWrapper
                venue={placeState.selectedVenue}
                markers={placeState.markers}
              />
            )}
        </Col>
      </Row>
    </Fragment>
  );
}

export default VenueDetails;
