import React, { useState } from "react";
import "./style.css";
import gmail from "../../assets/images/gmail.svg";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { useAuth } from "../../provider/AuthProvider";
import { RED_COLOR } from "../../constants";
import firebase from 'firebase';
import { logUser } from '../../services/API'

const LoginModal = React.memo((props: any) => {

  const { loginEmailPassword, loginGoogle, signup, sendEmailVerification } = useAuth();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [verifModal, setVerifModal] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(true);

  const handleEmail = (e: any) => { setEmail(e.target.value) }
  const handlePassword = (e: any) => { setPassword(e.target.value) }

  const handleLoginMailPassword = async () => {
    try {
      await loginEmailPassword(email, password)
      setVerifModal(false);
      props.onHide();
    } catch (e) {
      // TODO: Set message of wrong credentials
      setError('Wrong Credentials');
      console.log(error)
      setTimeout(() => setError(''), 1500);
    }
  }

  const handleSignupMailPassword = async () => {
    try {
      const creds = await signup(email, password);
      await sendEmailVerification(creds.user)      
      setVerifModal(true);
    } catch (e) {
      console.log(e)
    }
  }

  const handleLoginGoogle = async () => {
    try {
      await loginGoogle()
      if(firebase.auth().currentUser) {
        logUser({username:firebase.auth().currentUser?.email })
      }
      setVerifModal(false);
      props.onHide();
    } catch (error) {
      setError('Something happened when logging in with Google');
      setTimeout(() => setError(''), 1500);
    }
  }

  const handleSignUpButton = () => { setShowLoginModal(false) }
  const handleLoginButton = () => { setShowLoginModal(true) }
  const handleHideButton = () => {
    setShowLoginModal(true)
    props.onHide();
  }

  return (
    <div>
      <Modal show={props.show && !verifModal} onHide={handleHideButton}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showLoginModal ?
          <div>
            <Modal.Header>
              <div className="header"> Log In </div>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-2 input-text">
                <FormControl
                  placeholder="Email"
                  aria-label="Email"
                  onChange={handleEmail}
                  className="form-login"
                />
              </InputGroup>
              <InputGroup className="mb-2 input-text">
                <FormControl
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={handlePassword}
                  className="form-login"
                />
              </InputGroup>
              {error && <p style={{color: RED_COLOR, marginTop:'16px', marginBottom:0, textAlign:"center"}}>{error}</p>}
              <Button className="mb-4 mt-3 custom-button rounded" onClick={handleLoginMailPassword}>
                Continue
              </Button>
              <Button className="mb-2 social-button rounded" onClick={handleLoginGoogle}>
                <img className="button-icon" src={gmail} alt="Gmail Icon" />
                Sign In with Gmail
              </Button>
              <div className="centered">
                <Button onClick={handleSignUpButton} className="mt-3 mb-2 signup-button">
                  Sign up to log your preferences
                </Button>
              </div>
            </Modal.Body>
          </div>
          :
          <div>
            <Modal.Header>
              <div className="header"> Sign Up </div>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-2 input-text">
                <FormControl
                  placeholder="Email"
                  aria-label="Email"
                  onChange={handleEmail}
                />
              </InputGroup>
              <InputGroup className="mb-2 input-text">
                <FormControl
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={handlePassword}
                />
              </InputGroup>
              <Button className="mb-4 mt-3 custom-button rounded" onClick={handleSignupMailPassword}>
                Continue
              </Button>
              <Button className="mb-2 social-button rounded" onClick={handleLoginGoogle}>
                <img className="button-icon" src={gmail} alt="Gmail Icon" />
                Sign Up with Gmail
              </Button>
              <div className="centered">
                <Button onClick={handleLoginButton} className="mt-3 mb-2 signup-button">
                  Do you already have an account? Log In
                </Button>
              </div>
            </Modal.Body>
          </div>
        }
      </Modal>
      <Modal show={verifModal} onHide={handleHideButton}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="header"> Thanks for signing up! An email confirmation has been sent to you. </div>
        </Modal.Header>
        <Modal.Body>                    
          <Button className="mb-2 custom-button rounded" onClick={handleLoginGoogle}>
            <img className="button-icon" src={gmail} alt="Gmail Icon" />
            Click here to view your Saved Places
          </Button>
          <Button className="mb-2 custom-button rounded" onClick={handleLoginGoogle}>
            <img className="button-icon" src={gmail} alt="Gmail Icon" />
            Click here to view your Notifications
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
})

export default LoginModal;