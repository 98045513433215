import React, { Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Person from "../../components/icons/Person";
import MapWrapper from "../../components/Map/MapWrapper";
import "./style.css";
import { usePlaceState } from "../../store/place/placeContext";
import { useResultsState } from "../../store/results/resultsContext";
import { useLocation } from "react-router-dom";
import { makeAndHandleResultsRequest } from "../../utilities/serviceCalls";
import { isEmpty as _isEmpty } from "lodash";
import {
  level1,
  level2,
  level3,
  level4,
  level5
} from "../../utilities/personColors";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CrowdLevel() {
  const { placeState, dispatchPlaceState } = usePlaceState();
  // eslint-disable-next-line
  const { resultsState, dispatchResultsState } = useResultsState();
  const query = useQuery();
  const id = query.get("venueId");

  const getMap = async (id: any) => {
    let results = await makeAndHandleResultsRequest({
      id: id,
      location: { lat: null, lng: null },
      name: null
    });
    dispatchResultsState({
      type: "SET_VENUES",
      payload: results
    });
    dispatchPlaceState({
      type: "SET_SELECTED_VENUE",
      payload: results.venue
    });
  };

  const generateMap = () => {
    if (!_isEmpty(placeState.selectedVenue)) {
      return (
        <MapWrapper
          venue={placeState.selectedVenue}
          height="100vh"
          markers={placeState.markers}
        />
      );
    }
  };

  if (id) {
    getMap(id);
  }
  return (
    <Fragment>
      <Row
        style={{
          minHeight: "100vh",
          borderLeft: "solid 0.5px rgba(255,255,255,0.6)"
        }}
      >
        <Col xs={12} lg={6}>
          <Fragment>
            <div className="title-wrapper">
              <span>Our Crowd Level Index</span>
            </div>
            <div className="content-wrapper">
              <div className="title1">
                <span className="how-crowded">How crowded is it?</span>
                <div>
                  <Row>
                    <Col md={4}>
                      <div>
                        <Button className="lvl-btn-lvl1" variant="primary">
                          Level 1
                        </Button>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div>
                        {level1.map((color, index) => {
                          return (
                            <Person width="10%" key={index} color={color} />
                          );
                        })}
                      </div>
                      <div>
                        <span>Empty. You have the whole place to yourself</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="title2">
                <Row>
                  <Col md={4}>
                    <div>
                      <Button className="lvl-btn-lvl2">Level 2</Button>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      {level2.map((color, index) => {
                        return <Person width="10%" key={index} color={color} />;
                      })}
                    </div>
                    <div>
                      <span>
                        A few people. You can still move around easily
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="title2">
                <Row>
                  <Col md={4}>
                    <div>
                      <Button className="lvl-btn-lvl3">Level 3</Button>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      {level3.map((color, index) => {
                        return <Person width="10%" key={index} color={color} />;
                      })}
                    </div>
                    <div>
                      <span>Small groups. You need to walk around people</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="title2">
                <Row>
                  <Col md={4}>
                    <div>
                      <Button className="lvl-btn-lvl4">Level 4</Button>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      {level4.map((color, index) => {
                        return <Person width="10%" key={index} color={color} />;
                      })}
                    </div>
                    <div>
                      <span>
                        Big groups. You bump into large groups of people
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="title2" style={{ borderBottom: "none" }}>
                <Row>
                  <Col md={4}>
                    <div>
                      <Button className="lvl-btn-lvl5">Level 5</Button>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      {level5.map((color, index) => {
                        return <Person width="10%" key={index} color={color} />;
                      })}
                    </div>
                    <div>
                      <span>Packed. There are people everywhere</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fragment>
        </Col>
        <Col xs={12} lg={6}>
          {generateMap()}
        </Col>
      </Row>
    </Fragment>
  );
}

export default CrowdLevel;
