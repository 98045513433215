import { MAILGUN_API_KEY } from './config'
import axios, { AxiosResponse } from "axios";

export const postFeedbackMail = async (queryParams: {

    formData: FormData;
  }):  Promise<AxiosResponse> => { 
    return await axios.post(`https://api.mailgun.net/v3/mg.crowdalerts.com/messages`, 
    queryParams.formData
    ,{
      auth:{
        username:'api',
        password:MAILGUN_API_KEY
      },
      
    });
  };
