import React from "react";
import { timeConversionSlicker } from "../utilities/utilities";
import { NO_INFO } from "../constants";
import NoInfoWarn from "../assets/images/NoInfoWarning.png";

function CrowdSchedual(props: {
  scheduals: any;
  today: any;
}) {
  const { scheduals, today } = props;

  if (scheduals) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let today_schedual:string = scheduals[days[today.getDay()]].split("–")
  
    let open = today_schedual[0] === "Open 24 hours" ? "Open Now" : today.getHours() >= timeConversionSlicker(today_schedual[0]) &&
      today.getHours() < new Date().setHours(timeConversionSlicker(today_schedual[1]))? 
      'Open Now':'Closed'
    let formated = {
      'Monday': scheduals['Monday'], 
      'Tuesday': scheduals['Tuesday'], 'Wednesday': scheduals['Wednesday'],
      'Thursday': scheduals['Thursday'], 'Friday': scheduals['Friday'],
      'Saturday': scheduals['Saturday'], 'Sunday': scheduals['Sunday'], 
    }

    return (
      <div className='schedual-container'>
        <div style={{textAlign: 'center'}}><b>{open}</b></div>
        <div style={{margin: 'auto', padding: '10px 0'}}>
          {Object.keys(formated).map((day:any, i:number) => {
            let highlight = today.getDay() === i+1 ? 'white': 'rgba(255,255,255,0.6)'
            return (
              <div key={'sched-'+day} style={{padding: '5px 0', color: highlight, fontSize:14}}>
                {day.substring(0,3)}: {scheduals[day]}
              </div>
            )
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className='no-schedual-container'>
        <div style={{display: 'flex', alignItems: 'center', 
            flexDirection: 'column', justifyContent: 'center'}}>
          <img
            style={{ width: "40px", height: "40px", marginBottom: '10px' }}
            src={NoInfoWarn}
            alt="No Information Warning"
          />
          {NO_INFO}
        </div>
      </div>
    );
  }
}

export default CrowdSchedual;
