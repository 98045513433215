import React, { Fragment } from "react";
import PlaceInput from "../components/PlaceInput";
function Home() {

  return (
    <Fragment>
      <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <div style={{width: '65%', marginBottom: '10%'}}>
          <h1 className="text-white">Beat the crowds today!</h1>
          <h5 className="text-white" style={{marginBottom:70}}>
            Use Crowd Alerts to avoid crowds and social distance like a pro.
          </h5>
          <div>
            <PlaceInput buttonText ="Estimate Crowd" height={"50px"} margin={0} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
