import axios, { AxiosResponse } from "axios";

const BASE_URL = "https://us-central1-crowd-alerts.cloudfunctions.net";


const headers = {
  "Content-Type": "application/json",
};

export const fetchResults = async ({
  id,
  name = '',
  location = {}
}: {
  id: string;
  name?: string;
  location?: {};
  key: string;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${BASE_URL}/crowd_analytics`,
    {
      id,
      name,
      location
    },
    { headers }
  );
};

export const fetchResultsByLocation = async ({
  location,
  recommendations = false,
  times = false,
  detail_level = 1,
}: {
  location: { latitude: number; longitude: number };
  recommendations?: boolean;
  times?: boolean;
  detail_level?: number;
  key: string;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${BASE_URL}/crowd_analytics`,
    {
      location,
      recommendations,
      times,
      detail_level,
    },
    { headers }
  );
};

export const setCrowdLevel = async (queryParams: {
  latitude: number;
  longitude: number;
  source: string;
  crowdLevel: number;
  platform: string;
  key:string
  venueId:string;
  userName: string | null | undefined;
}): Promise<any> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(queryParams)
  };
  return await fetch('https://crowd-alerts.wl.r.appspot.com/postCrowdedInformation', requestOptions)
  .then(response => {
    return response;
  })
};

export const logUser = async (queryParams: {
  username: string | null | undefined;
}): Promise<any> => {
  if(queryParams.username) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(queryParams)
    };
    return await fetch('https://crowd-alerts.wl.r.appspot.com/daily', requestOptions)
    .then(response => {
      return response;
    })
  }
};

export const fetchHistorical = async ({
  latitude,
  longitude,
  radius = 5000,
  key,
}: {
  latitude: number; 
  longitude: number;
  radius?: number;
  key: string;
}): Promise<any> => {
  // console.log(latitude, longitude, radius)
  // latitude = 37.66118240356445
  // longitude = -122.40113830566406
  return await axios.get(
    `${BASE_URL}/historical`,{
      params: {
        latitude,
        longitude,
        radius,
        key,
      },
    }
  );
};

export const setLinesOutside = async (queryParams: {
  latitude: number;
  longitude: number;
  source: string;
  platform: string;
  key:string;
  lineOutside:boolean;
  venueId:string;
}): Promise<any> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(queryParams)
  };
  return await fetch('https://crowd-alerts.wl.r.appspot.com/lineOutside', requestOptions)
  .then(response => {
    return response;
  })
};