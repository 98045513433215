import React, { useState } from "react";
import {
  makeAndHandleSetCrowdLevel,
  makeAndHandleResultsRequest
} from "../utilities/serviceCalls";
import { usePlaceState } from "../store/place/placeContext";
import { useResultsState } from "../store/results/resultsContext";
import crowd_success from "../assets/images/thanks_tara.svg";
import Person from "./icons/Person";
import { Row, Col, Modal, Button, Badge } from "react-bootstrap";
import { CROWD_COLORS } from "../constants";

function CrowdAlerts(props: {
  name?: string;
  address?: string;
  crowdMessage: string[];
}) {
  const { name, address, crowdMessage } = props;
  const { placeState, dispatchPlaceState } = usePlaceState();
  // eslint-disable-next-line
  const { resultsState, dispatchResultsState } = useResultsState();
  const [levelsState, setLevelsState] = useState<{
    hover: boolean;
    crowd: number;
    showNotice: boolean;
    showSuccess: boolean;
    crowdColors: string[];
    crowdColorsAux: string[];
    crowdColorsSelected: string[];
  }>({
    hover: false,
    crowd: 0,
    showNotice: false,
    showSuccess: false,
    crowdColors: ["#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0"],
    crowdColorsAux: ["#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0"],
    crowdColorsSelected: ["#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0"]
  });

  const handleHoverOn = (id: number): void => {
    switch (id) {
      case 0:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#53ADF6", "#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0"],
          crowdColorsAux: [
            "#53ADF6",
            "#D0D0D0",
            "#D0D0D0",
            "#D0D0D0",
            "#D0D0D0"
          ]
        });
        break;
      case 1:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#83D376", "#83D376", "#D0D0D0", "#D0D0D0", "#D0D0D0"],
          crowdColorsAux: [
            "#83D376",
            "#83D376",
            "#D0D0D0",
            "#D0D0D0",
            "#D0D0D0"
          ]
        });
        break;
      case 2:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#F5D254", "#F5D254", "#F5D254", "#D0D0D0", "#D0D0D0"],
          crowdColorsAux: [
            "#F5D254",
            "#F5D254",
            "#F5D254",
            "#D0D0D0",
            "#D0D0D0"
          ]
        });
        break;
      case 3:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#FF7D05", "#FF7D05", "#FF7D05", "#FF7D05", "#D0D0D0"],
          crowdColorsAux: [
            "#FF7D05",
            "#FF7D05",
            "#FF7D05",
            "#FF7D05",
            "#D0D0D0"
          ]
        });
        break;
      case 4:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#F22B29", "#F22B29", "#F22B29", "#F22B29", "#F22B29"],
          crowdColorsAux: [
            "#F22B29",
            "#F22B29",
            "#F22B29",
            "#F22B29",
            "#F22B29"
          ]
        });
        break;
      default:
        setLevelsState({
          ...levelsState,
          crowdColors: ["#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0", "#D0D0D0"]
        });
        break;
    }
  };

  const handleHoverOff = (): void => {
    setLevelsState({
      ...levelsState,
      crowdColors: levelsState.crowdColorsSelected
    });
  };

  const sendCrowd = async (crowd: number): Promise<void> => {
    crowd = crowd + 1;
    let result = await makeAndHandleSetCrowdLevel({
      latitude: placeState.selectedVenue.location.lat,
      longitude: placeState.selectedVenue.location.lng,
      crowdLevel: crowd,
      venueId: placeState.selectedVenue.id
    });

    if (result) {
      setLevelsState({
        ...levelsState,
        crowd: crowd,
        showNotice: true,
        crowdColorsSelected: levelsState.crowdColorsAux,
        crowdColors: levelsState.crowdColorsAux
      });

      const results = await makeAndHandleResultsRequest({
        id: placeState.selectedVenue.id,
        location: {
          lat: placeState.selectedVenue.location.lat,
          lng: placeState.selectedVenue.location.lng
        }
      });

      dispatchResultsState({
        type: "SET_VENUES",
        payload: results
      });

      // rather than call API set from current state
      let markers: any = [];
      markers.push({
        name: placeState.selectedVenue.name,
        lat: placeState.selectedVenue.location.lat,
        lng: placeState.selectedVenue.location.lng,
        index: crowd
      });
      dispatchPlaceState({
        type: "SET_MARKERS",
        payload: markers
      });
    }
  };

  const handleCloseNotice = () => {
    setLevelsState({
      ...levelsState,
      showNotice: false,
      crowdColors: levelsState.crowdColorsSelected
    });
  };

  return (
    <div>
      <Row>
        <Col>
          {levelsState.crowdColors.map((color: any, i: number) => {
            return (
              <Person
                key={i}
                handleHoverOn={() => handleHoverOn(i)}
                handleHoverOff={handleHoverOff}
                handleClick={() => sendCrowd(i)}
                color={color}
              />
            );
          })}
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center", fontSize: "10px" }}>
          {crowdMessage[levelsState.crowd].split(".")[0]}
        </Col>
      </Row>

      <Modal
        show={levelsState.showNotice}
        onHide={handleCloseNotice}
        style={{ textAlign: "center" }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ display: "block" }}>
          <img src={crowd_success} alt="success" />
          <div>Thank you, for your input!</div>
          <button
            type="button"
            className="close"
            onClick={handleCloseNotice}
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              padding: 0,
              margin: 0
            }}
          >
            <span aria-hidden="true" style={{ color: "white" }}>
              ×
            </span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "12px" }}>
        <div style={{ marginBottom: "20px" }}>
            <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>
              <b>{name}</b>
            </div>
            <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>{address}</div>
          </div>
          <div style={{marginBottom: '10px'}}>
            <div>You gave a</div>
            <Badge
              variant="warning"
              style={{ background: CROWD_COLORS[levelsState.crowd] }}
            >
              Level {levelsState.crowd}
            </Badge>
            <div>crowd level at this time.</div>
          </div>
          <div style={{ marginBottom: "10px" }}>
            {levelsState.crowdColorsAux.map((color: any, i: number) => {
              return <Person key={i} color={color} />;
            })}
          </div>
          <div>{crowdMessage[levelsState.crowd]}</div>
        </Modal.Body>
        <Modal.Footer style={{ display: "contents" }}>
          <Button variant="danger" onClick={handleCloseNotice}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CrowdAlerts;
