import React from "react";

function SearchIcon({
  color = "#FFF",
  width = "36px",
  height = "36px",
  opacity = "0.6",
}: {
  color?: string;
  width?: string;
  height?: string;
  opacity?: string;
}) {
  return (
    <svg 
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 25 25"
      fill="none"xmlns="http://www.w3.org/2000/svg">
      <path fill={color} 
        d="M8 16c1.775 0 3.499-.595 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396C15.405 11.5 16 9.775 16 8c0-4.411-3.589-8-8-8S0 3.589 0 8s3.589 8 8 8zM8 2c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/>
    </svg>
  );
}

export default SearchIcon;
