
import React, { useState } from "react";
import "../styles/ToggleSwitch.css";
  
function ToggleSwitch (props: { 
  label:string,
  text: string,
  className?:string,
  disabled?: boolean,
  checked: boolean,
  onChange: (e : React.ChangeEvent<HTMLInputElement>)=>void
}) {

  return (
    <div className="cont">
      <span className="text-switch" >
        {props.text}{" "}
      </span>
      
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={props.label} id={props.label} onChange={e => props.onChange(e)} checked={props.checked}/>
        <label className="label" htmlFor={props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;