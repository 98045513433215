import React, { useState, useEffect } from "react";
import crowd_sign from "../assets/images/crowd_sign.png";
import crowd_success from "../assets/images/thanks_tara.svg";
import { useHistory } from "react-router-dom";
import { Card, Row, Col, Badge, Form, Modal, Button } from "react-bootstrap";
import { Minivenue } from "../types/foursquare";
import { formatAddress, formatDistance, calculateDistance, listContainVenue, removeVenueFromList } from "../utilities/utilities";
import { SECTION_COLOR, SUBSECTION_COLOR, WHITE_COLOR, CROWD_MESSAGE, CROWD_COLORS, LocalStorage } from "../constants";
import { useGeoLocation } from "../hooks/useGeolocation";
import CrowdCharts from "./CrowdCharts";
import CrowdLevels from "./CrowdLevels";
import CrowdSchedual from "./CrowdSchedual";
import ArrowRight from "./icons/ArrowRight";
import { makeAndHandleSetLinesOutside,  makeAndHandleResultsRequest } from "../utilities/serviceCalls";
import { useResultsState } from "../store/results/resultsContext";
import { usePlaceState } from "../store/place/placeContext";
import { dayNameToNumber, checkLineOutside } from "../utilities/utilities";
import { LineOutsideContext } from "../contexts";
import defaultVenueIcon from "../assets/images/defaultVenueIcon.svg";
import subscibreBell from "../assets/images/bell.svg";
import BellIcon from "./icons/BellIcon";
import HeartCheckBox from "./HeartCheckBox";
import Lockr from "lockr";
import { SavedPlacesContext } from "../contexts";
import { crowdAnalytics2saved } from "../utilities/convertVenues";
import { SubscribedPlacesContext,  AlertsConfigContext } from "../contexts";
import { isEmpty as _isEmpty } from "lodash";
import { useAuth } from "../provider/AuthProvider";
import { makeAndHandleCreateAlert, makeAndHandleDeleteAlert } from '../utilities/serviceCalls'
import { premiumUser, subscribreVenue, unsubscribreVenue } from "../utilities/alertsConfig";

import db from '../firebase';


function EstimatedCrowd(props: {
  crowd: any;
  venue: Minivenue;
  className?: string;
}) {
  // eslint-disable-next-line
  const { resultsState, dispatchResultsState } = useResultsState();
  // eslint-disable-next-line
  const { placeState, dispatchPlaceState } = usePlaceState();
  const [lineOutsideHistory, setLineOutsideHistory] = React.useContext(LineOutsideContext)
  const { latitude, longitude } = useGeoLocation();
  const [ configState, setConfigState ] = React.useContext(AlertsConfigContext)
  // const location = useGeoLocation();
  const history = useHistory();
  const { crowd, venue } = props;
  const [lineOutside, setLineOutside] = useState(false)
  const [linesNotif, showLinesNotif] = useState<{ show: boolean; }>({ show: false, });
  const [savedPlaces, setSavedPlaces] = React.useContext(SavedPlacesContext)
  const [subscribedPlaces, setSubscribedPlaces] = React.useContext( SubscribedPlacesContext)
  const [favorite, setFavorite] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [premium, setPremium] = useState(false)
  const { user, authUser } = useAuth();
  // eslint-disable-next-line
  React.useEffect(() => {
    const newLineState = checkLineOutside(lineOutsideHistory, venue.id, 3600)
    setLineOutside(newLineState)
    setFavorite(listContainVenue(savedPlaces, venue))
    setSubscribed(listContainVenue(subscribedPlaces, venue))
  })
  useEffect(() => {
    authUser().then((actualUser: any) => {
      premiumUser(actualUser).then((data : any | undefined) =>{
        if(data !== undefined && data.premium){
          setPremium(true)
        }
      })
      
  });
  }, []);

  let distance = ''
  if (latitude !== '' && longitude !== '') {
    let calculatedDistance = calculateDistance(
      { lat: latitude, lng: longitude },
      { lat: venue.location.lat, lng: venue.location.lng }
    )
    distance = formatDistance(calculatedDistance)
  }

  let today = null;
  if (venue.local_hour && venue.local_hour.hour) {
    today = {
      getHours: function () {
        return venue.local_hour.hour;
      },
      getDay: function () {
        return dayNameToNumber(venue.local_hour.day);
      }
    };
  } else {
    today = new Date();
  }

  let short_mssg
  if (crowd.crowd.hereNow != null) {
    short_mssg = crowd.crowd.hereNow + ' people'
  } else {
    // add validation for < 1 and > 5
    short_mssg = CROWD_MESSAGE[crowd.crowd.index_level].split('.')[0]
  }

  const executeSimilarNearby = () => {
    history.push("/results");
  };

  const handleLinesCheck = async (check: any): Promise<void> => {
    let lines: boolean = check.target.checked
    let result = await makeAndHandleSetLinesOutside({
      latitude: venue.location.lat,
      longitude: venue.location.lng,
      lineOutside: lines,
      venueId: venue.id
    })
    if (result) {
      setLineOutside(lines)
      showLinesNotif({ show: true })
      setLineOutsideHistory({ ...lineOutsideHistory, [venue.id]: Date.now() })
      // Call the crowd_analytics api and update state
      const results = await makeAndHandleResultsRequest({
        id: venue.id,
        location: {
          lat: venue.location.lat,
          lng: venue.location.lng
        }
      });

      dispatchResultsState({
        type: "SET_VENUES",
        payload: results
      });

      dispatchPlaceState({
        type: "SET_SELECTED_VENUE",
        payload: results.venue
      });
    }
  }

  const handleCloseSuccess = () => {
    showLinesNotif({ show: false })
  }
  const onHeartCheck = (event: any) => {
    event.stopPropagation()
    if (favorite) {
      const savedPlaces = Lockr.smembers(LocalStorage.SAVEDPLACES)
      const newSavedPlaces = removeVenueFromList(savedPlaces, crowdAnalytics2saved(venue))
      Lockr.set(LocalStorage.SAVEDPLACES, newSavedPlaces)
      setSavedPlaces(newSavedPlaces) // Update global state

    } else {
      Lockr.sadd(LocalStorage.SAVEDPLACES, crowdAnalytics2saved(venue))
      const newSavedPlaces = Lockr.smembers(LocalStorage.SAVEDPLACES)
      setSavedPlaces(newSavedPlaces) // Update global state
    }
    setFavorite(favorite ? false : true)
  }

  const onSubscribe = async(event: any) => { 
    const playerId = Lockr.smembers(LocalStorage.PLAYERID)

    event.stopPropagation()
    if (subscribed) {
      const newSubscribedPlaces = await unsubscribreVenue(venue, user, configState.sync)
      setSubscribedPlaces(newSubscribedPlaces)
    } else {
      const newSubscribedPlaces = await subscribreVenue(venue, user, configState.sync)
      setSubscribedPlaces(newSubscribedPlaces)
    }
    setSubscribed(subscribed ? false : true)
  }
  const displayLineOutsideBadge = venue.line_outside
  const venueIcon = crowd.venue.icon || defaultVenueIcon

  return (
    <div>
      <Card className={props.className}
        style={{ borderRadius: '0px', background: SECTION_COLOR, color: WHITE_COLOR, padding: '15px, 15px, 15px, 5px', marginBottom: '5px !important' }}>
        <Card.Body style={{ padding: '0px' }}>
          <Row>
            <Col xs={2}>
              <div style={{ margin: 'auto', width: '50px' }}>
                <img src={venueIcon} alt="" width='50px' height='50px' />
              </div>
            </Col>
            <Col xs={6}>
              <div>{venue.name}</div>
              <div style={{ fontSize: '10px', position: 'absolute', bottom: '0px' }}>
                <span>{distance}</span> &#8226; {venue.location.formattedAddress[0]}
              </div>
            </Col>
            {/* <Col xs={1}>
              <div style={{ textAlign: 'right' }}>
                <HeartCheckBox checked={true} onClick={() => { }} />
              </div>
            </Col> */}
            <Col xs={4}>
              <div style={{ textAlign: 'right' }}>
                <div>
                  
                  { user && !subscribed && premium && 
                  <div style={{  position: 'absolute',width:'40px', bottom:8, right:120}}>
                    <BellIcon width="20px" height="20px" onClick={onSubscribe} style={{marginRight:'8px', marginBottom:-10}} /> 
                    <span style={{fontSize:'8px', bottom:0}}>Subscribe</span>
                  </div>}
                  { user && subscribed && premium &&
                  
                  <img alt="Subscribe" src={subscibreBell}  onClick={onSubscribe} style={{marginRight:12}}></img>
                 
                 
                  }
                  <span style={{ paddingRight: "20px", }}>
                    
                    <HeartCheckBox checked={favorite} onClick={onHeartCheck} /></span>
                  <Badge variant="warning" style={{ background: CROWD_COLORS[crowd.crowd.index_level] }}>Level {crowd.crowd.index_level}</Badge>
                </div>
                <div style={{ fontSize: '10px', position: 'absolute', top: '25px', bottom: '0', right: '15px' }}>
                  <b>{short_mssg}</b>
                  {displayLineOutsideBadge && (<Badge variant="primary" style={{ fontWeight: "normal", borderRadius: "0px", fontSize: "10px", background: "#4389C3" }}>Lines outside </Badge>)}
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col xs={5}>
              <CrowdSchedual
                scheduals={crowd.venue.open}
                today={today}
              />
            </Col>
            <Col xs={7}>
              <CrowdCharts
                actual={crowd.crowd.percent_full}
                times={crowd.venue.popularetimes}
                today={today}
                range={[0, 23]}
              />
              <p style={{ fontSize: "10px", fontWeight: "bold" }}>Log current crowd levels of this location</p>
              <Row style={{ margin: 0 }}>
                <Col sm={7}>
                  <img src={crowd_sign} style={{ height: '15px', position: 'absolute', left: 0, top: 1 }} alt="" />
                  <div style={{ fontSize: '11px' }}>
                    <b>How crowded is it?</b>
                  </div>
                  <div style={{ fontSize: '9px', marginLeft: '-15px', marginTop: '5px' }}>
                    <Form>
                      <Form.Group controlId="lines-checks" style={{ margin: 0, lineHeight: '22px' }}>
                        <Form.Check type="checkbox" label="Lines outside the building" checked={lineOutside}
                          onChange={handleLinesCheck} />
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col sm={5} style={{ padding: '0px' }}>
                  <CrowdLevels
                    name={venue.name}
                    address={formatAddress(venue)}
                    crowdMessage={CROWD_MESSAGE}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ background: SUBSECTION_COLOR, marginTop: '30px' }}>
            <div className='venues-link'
              onClick={executeSimilarNearby}
              style={{color:'rgba(255,255,255,0.6)'}}>
              View similar venues
            <h6 style={{ margin: '0', float: 'right' }}>
                <ArrowRight></ArrowRight>
              </h6>
            </div>
            <hr style={{ color: "rgba(255,255,255,0.6)", backgroundColor: 'rgba(255,255,255,0.6)', margin: '0 5px' }} />
            <div className='venues-link'
              onClick={executeSimilarNearby}
              style={{color:'rgba(255,255,255,0.6)'}}>
              View nearby venues
            <h6 style={{ margin: '0', float: 'right' }}>
                <ArrowRight></ArrowRight>
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={linesNotif.show} onHide={handleCloseSuccess} style={{ textAlign: 'center' }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header style={{ display: 'block' }}>
          <img src={crowd_success} alt="" />
          <div>Thank you for your input!</div>
          <br />
          <div>
            <Form>
              <Form.Group controlId="lines-checks" style={{ margin: 0, fontSize: '12px', lineHeight: '22px' }}>
                <Form.Check type="checkbox" label="Lines outside the building" readOnly checked={lineOutside} />
              </Form.Group>
            </Form>
          </div>
        </Modal.Header>
        <Modal.Footer style={{ display: 'contents' }}>
          <Button variant="danger" onClick={handleCloseSuccess}>
            Close
					</Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default EstimatedCrowd;
