import { fetchResults, fetchResultsByLocation, setCrowdLevel, setLinesOutside, fetchHistorical } from "../services/API";
import { fetchAutocompleteVenues, fetchNearbyVenues } from "../services/Foursquare";
import { fetchSimilarVenues } from "../services/GooglePlace";
import { postGeolocation } from "../services/Location";
import { fetchGeocoder } from "../services/RentaliosAPI";
import { AutocompleteResponse } from "../types/foursquare";
import { ResultsResponse } from "../types/api";
import { formatFoursquareDate } from "./utilities";
import { Nearby } from "../types/nearby";
import { Similar } from "../types/similar";
import { LocationResponse } from "../types/locations";
import { fetchAllLocations } from "../services/Dashboard";
import { postFeedbackMail } from "../services/FeedbackMail";
import { registerUser, updateUser, createAlert, deleteAlert } from "../services/CrowdLocationServer"
import { alertConfig } from "../types/alertConfig"
import firebase from 'firebase';

async function makeAndHandleDeleteAlert(
  username: string,
  venueId: string,
): Promise<any> {
  let res;
  try {
    const queryParams  = {

      username: username,
      venueId: venueId,
    };
    res = await deleteAlert(queryParams)
    
  } catch (err) {
    console.error(err);
  }
  return res;
}

async function makeAndHandleCreateAlert(
  username: string,
  venueId: string
  
): Promise<any> {
  let res;
  try {
    const queryParams  = {
      username: username,
      venueId: venueId
    };
    res = await createAlert(queryParams) 

  } catch (err) {
    console.error(err);
  }
  return res;
}

async function makeAndHandleUpdateUser(

  username: string,
  attr: string,
  value: boolean,
  
): Promise<any> {
  let res;
  try {
    let attribute : string;
    switch (attr) {
      case "l1Sound":{
        attribute = 'One sound';
        break;
      }
      case "l1Pop":{
        attribute = 'One push';
        break;
      }
      case "l5Sound":{
        attribute = 'Five sound';
        break;
      }
      case "l5Pop":{
        attribute = 'Five push';
        break;
      }
      case "sync":{
        attribute = 'Sync';
        break;
      }
      default:{
        return;
      }
    }
    const queryParams  = {
      username: username,
      attr: attribute,
      value: value
    };
    res = await updateUser(queryParams)

  } catch (err) {
    console.error(err);
  }
  return res;
}

async function makeAndHandleRegisterUser(
  playerId: string | null| undefined,
  username: string,
  settings: alertConfig,
  
): Promise<any> {
  let res; 

  try {
    const queryParams  = {
      onePush: settings.l1Pop,
      oneSound: settings.l1Sound,
      fivePush: settings.l5Pop,
      fiveSound: settings.l5Sound,
      sync : settings.sync,
      playerId: playerId,
      username: username,
    };
    res = await registerUser(queryParams)
    
  } catch (err) {
    console.error(err);
  }

  return res;
}


async function makeAndHandleFeedbackPost(
  email: string,
  text: string,
  file?: File,
): Promise<any> {
  let res;
  try {
    const formData = new FormData();
    formData.append('from', 'feedbackWeb@mg.crowdalerts.com');
    formData.append('to', 'info@crowdalerts.com');
    formData.append('subject', 'Crowd Alerts Feedback');
    formData.append('text', text);
    if(email !== '')
      formData.append('h:Reply-To', email);
    if(file !== undefined)
        formData.append('"attachment"; filename="image.JPG"', file);
    res = await postFeedbackMail({formData})
    
  } catch (err) {
    console.error(err);
  }
  return res;
}

async function makeAndHandleLocationServiceRequest(
  latitude: string | number,
  longitude: string | number
): Promise<any> {
  let data;
  try {
    latitude = Number(latitude);
    longitude = Number(longitude);
    const payload = {
      latitude,
      longitude,
    };
    const geolocationRes = await postGeolocation(payload);
    if (geolocationRes.status === 200) {
      data = geolocationRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleResultsRequest(
  selectedVenue: any
): Promise<ResultsResponse> {
  let data;

  const payload = {
    id: selectedVenue.id,
    name: selectedVenue.name,
    location: {
      latitude: selectedVenue.location.lat,
      longitude: selectedVenue.location.lng
    },
    key:'5dnJv&@rVZ%zsFYM3T$Y3tbh'
  };
  try {
    const resultRes = await fetchResults(payload);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleResultsRequestById(
  id: string
): Promise<ResultsResponse> {
  let data;

  const payload = {
    id: id,
    recommendations: false,
    times: false,
    key:'5dnJv&@rVZ%zsFYM3T$Y3tbh'
  };
  try {
    const resultRes = await fetchResults(payload);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleResultsRequestByLatLng(
  lat: number,
  lng: number
): Promise<ResultsResponse> {
  let data;

  const payload = {
    location: {
      latitude: lat,
      longitude: lng,
    },
    detail_level: 3,
    recommendations: true,
    times: true,
    key:'5dnJv&@rVZ%zsFYM3T$Y3tbh'
  };
  try {
    const resultRes = await fetchResultsByLocation(payload);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleAutocompleteVenueRequest(params: {
  venueQuery: string;
  latitude: string;
  longitude: string;
}): Promise<AutocompleteResponse> {
  let data;

  const queryParams = {
    query: params.venueQuery,
    ll: `${params.latitude},${params.longitude}`,
    v: '20180323'
  };
  try {
    const resultRes = await fetchAutocompleteVenues(queryParams);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleSimilarVenueRequest(params: {
  id: string;
  latitude: number;
  longitude: number;
  venueType: string;
}): Promise<Similar> {
  let data;
  const queryParams = {
    id: params.id,
    ll: `${params.latitude},${params.longitude}`,
    v: formatFoursquareDate(),
    venueType: params.venueType
  };
  try {
    const resultRes = await fetchSimilarVenues(queryParams);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleNearbyVenueRequest(params: {
  radius: number;
  latitude: number;
  longitude: number;
}): Promise<Nearby> {
  let data;

  const queryParams = {
    radius: params.radius,
    ll: `${params.latitude},${params.longitude}`,
    v: formatFoursquareDate(),
  };
  try {
    const resultRes = await fetchNearbyVenues(queryParams);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleGeocodeRequest(params: {
  address: string;
  lat: string;
  lng: string;
}): Promise<any> {
  let data;

  const queryParams = {
    address: params.address,
    lat: params.lat,
    lng: params.lng,
  };
  try {
    const resultRes = await fetchGeocoder(queryParams);
    if (resultRes.status === 200) {
      data = resultRes.data;
    }
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleSetCrowdLevel(params: {
  latitude: number;
  longitude: number;
  crowdLevel: number;
  venueId: string;
}): Promise<any> {

  const queryParams = {
    latitude: params.latitude,
    longitude: params.longitude,
    venueId: params.venueId,
    source: 'CrowdAlerts',
    crowdLevel: params.crowdLevel,
    platform: "Web",
    key:'ET&atxvA@AcdB6Wj!3#799AU',
    userName: firebase.auth().currentUser?.email
  };
  
  try {
    const resultRes = await setCrowdLevel(queryParams);
    return resultRes.ok
  } catch (err) {
    console.error(err);
  }
}

async function makeAndHandleSetLinesOutside(params: {
  latitude: number;
  longitude: number;
  lineOutside: boolean;
  venueId: string;
}): Promise<any> {

  const queryParams = {
    latitude: params.latitude,
    longitude: params.longitude,
    source: 'CrowdAlerts',
    lineOutside: params.lineOutside,
    venueId: params.venueId,
    platform: "Web",
    key:'ET&atxvA@AcdB6Wj!3#799AU'
  };
  
  try {
    const resultRes = await setLinesOutside(queryParams);
    return resultRes.ok
  } catch (err) {
    console.error(err);
  }
}

async function makeAndHandleHistorical(params: {
  latitude: number;
  longitude: number;
  radius: number;
}): Promise<any> {
  let data;

  const queryParams = {
    latitude: params.latitude,
    longitude: params.longitude,
    radius: params.radius,
    key: '5dnJv&@rVZ%zsFYM3T$Y3tbh',
  };
  
  try {
    const resultRes = await fetchHistorical(queryParams);
    data = resultRes;
  } catch (err) {
    console.error(err);
  }
  return data;
}

async function makeAndHandleLocationsDashboard(params: {
  latitude: number,
  longitde: number,
  radius?: number,
  startDate?: string,
  endDate?: string,
  realtime?: boolean,
  boundingBox?: number[]
}): Promise<LocationResponse> {
  let data;

  const queryParams = {
    latitude: params.latitude,
    longitude: params.longitde,
    radius: params.radius,
    startDate: params.startDate,
    endDate: params.endDate,
    realtime: params.realtime,
    boundingBox: params.boundingBox
  }
   
  try {
    const resultRes = await fetchAllLocations(queryParams);        
    if (resultRes.status === 201) {
      data = resultRes.data;
    }
  } catch (err) {
    console.log(err)
  }

  return data
}

export {
  makeAndHandleResultsRequest,
  makeAndHandleResultsRequestById,
  makeAndHandleResultsRequestByLatLng,
  makeAndHandleAutocompleteVenueRequest,
  makeAndHandleSimilarVenueRequest,
  makeAndHandleNearbyVenueRequest,
  makeAndHandleLocationServiceRequest,
  makeAndHandleGeocodeRequest,
  makeAndHandleSetCrowdLevel,
  makeAndHandleSetLinesOutside,
  makeAndHandleHistorical,
  makeAndHandleLocationsDashboard,
  makeAndHandleFeedbackPost,
  makeAndHandleRegisterUser,
  makeAndHandleUpdateUser,
  makeAndHandleCreateAlert,
  makeAndHandleDeleteAlert,

};
