import axios, { AxiosResponse } from "axios";

const BASE_URL = "https://us-central1-crowd-alerts.cloudfunctions.net";

export const fetchGeocoder = async (queryParams: {
  address: string;
}): Promise<AxiosResponse> => {
  return await axios.get(`${BASE_URL}/places`, {
    params: {
      ...queryParams,
    },
  });
};
