import React, { Fragment, useState, useEffect, useRef } from 'react'
import {
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Form,
  Button,
} from 'react-bootstrap'
import {
  TEXT_DARK_LIGHT,
  RED_COLOR,
  WHITE_COLOR,
  SUBSECTION_COLOR,
  SECTION_COLOR,
  LocalStorage,
} from '../constants'
import { isArguments, isEmpty as _isEmpty } from 'lodash'
import ToggleSwitch from '../components/ToggleSwitch'
import MapWrapper from '../components/Map/MapWrapper'
import {} from '../utilities/serviceCalls'
import { usePlaceState } from '../store/place/placeContext'
import {
  SavedPlacesContext,
  RecentPlacesContext,
  SubscribedPlacesContext,
  AlertsConfigContext,
} from '../contexts'
import { useGeoLocation } from '../hooks/useGeolocation'
import SubscribedCard from '../components/SubscribedCard'
import Lockr from 'lockr'
import { useAuth } from '../provider/AuthProvider'
import { useHistory, useLocation } from 'react-router-dom'
import db from '../firebase'

import OneSignal from 'react-onesignal'
import {
  makeAndHandleRegisterUser,
  makeAndHandleUpdateUser,
  makeAndHandleResultsRequest,
} from '../utilities/serviceCalls'
import { crowdAnalytics2saved } from '../utilities/convertVenues'
import { removeVenueFromList, listContainVenue } from '../utilities/utilities'
import {
  makeAndHandleCreateAlert,
  makeAndHandleDeleteAlert,
} from '../utilities/serviceCalls'
import {
  premiumUser,
  subscribreVenue,
  unsubscribreVenue,
} from '../utilities/alertsConfig'
import PaymentError from '../components/PaymentError'
import PaymentSuccess from '../components/PaymentSuccess'


interface Ijsx {
  arr: Array<any>
}

function Alerts() {
  const { placeState, dispatchPlaceState } = usePlaceState()
  const [subscribedPlaces, setSubscribedPlaces] = React.useContext(
    SubscribedPlacesContext
  )
  const [state, setState] = React.useContext(AlertsConfigContext)
  const { latitude, longitude } = useGeoLocation()
  const origin = { lat: Number(latitude), lng: Number(longitude) }
  const [userState, setUserState] = useState({
    isAuthenticating: true,
    isPremium: false,
  })
  const { user, authUser } = useAuth()
  const [jsx, setJsx] = useState<Ijsx>({ arr: [] })
  const history = useHistory()
  var actUser: any
  const generateMap = () => {
    if (!_isEmpty(placeState.selectedVenue)) {
      return (
        <MapWrapper
          venue={placeState.selectedVenue}
          height="100vh"
          markers={placeState.markers}
        />
      )
    }
  }

  const css = `
    .payment-error-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }`

  const promiseUser = async (actualUser: any) => {
    fetchAccounts(actualUser)

    OneSignal.getUserId().then((userId) => {
      makeAndHandleRegisterUser(userId, actualUser.email, state)
    })
  }

  useEffect(() => {
    authUser().then(
      (actualUser: any) => {
        promiseUser(actualUser)
        if (state.sync) {
          db.firestore()
            .collection('accounts')
            .doc(actualUser.email)
            .onSnapshot(async (doc) => {
              const response = db
                .firestore()
                .collection('accounts')
                .doc(actualUser.email)
                .get()

              const data = (await response).data()
              if (data) {
                onSync(data, actualUser, false)
              }
            })
        }
      },
      () => {
        setUserState({ ...userState, isAuthenticating: false })
      }
    )
  }, [])

  useEffect(() => {
    var subscribedPlacesJsx: Array<any> = []
    for (let i = subscribedPlaces.length - 1; i >= 0; i--) {
      subscribedPlacesJsx.push(
        <SubscribedCard
          key={subscribedPlaces[i].id}
          className="mt-1 shadow p-3"
          venue={subscribedPlaces[i]}
          origin={origin}
          user={user}
        />
      )
    }
    setJsx({ arr: subscribedPlacesJsx })
  }, [subscribedPlaces, user])

  const onSync = async (
    data: firebase.firestore.DocumentData | undefined,
    actualUser: any,
    update: boolean
  ) => {
    if (data === undefined || actualUser === null) return

    var newSubscribedPlace = subscribedPlaces
    if (!update) {
      newSubscribedPlace = []
    }
    var promises: Array<any> = []
    data.alerts.forEach((alert: any, index: any) => {
      promises.push(
        makeAndHandleResultsRequest({
          id: alert.id,
          name: alert.name,
          location: { lat: alert.location.lat, lon: alert.location.lon },
        }).then((newResult) => {
          if (newResult !== undefined) {
            const newPlace = crowdAnalytics2saved(newResult.venue)
            if (!listContainVenue(newSubscribedPlace, newPlace)) {
              newSubscribedPlace.push(newPlace)
            }
            makeAndHandleCreateAlert(actualUser.email, newPlace.id)
          }
        })
      )
    })

    Promise.all(promises).then(() => {
      db.firestore()
        .collection('accounts')
        .doc(actualUser.email)
        .update({ alerts: newSubscribedPlace })
      Lockr.set(LocalStorage.SUBSCRIBEDPLACES, newSubscribedPlace)
      setSubscribedPlaces([]) //// is this needed
      setSubscribedPlaces(newSubscribedPlace)
    })
  }

  const handleChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const element = e.target as HTMLButtonElement
    const name = element.name as
      | 'l1Sound'
      | 'l1Pop'
      | 'l5Sound'
      | 'l5Pop'
      | 'sync'
    const newState = { ...state, [name]: !state[name] }
    makeAndHandleUpdateUser(user.email, name, !state[name])
    setState(newState, Lockr.set(LocalStorage.ALERTSCONFIG, newState))
    if (name === 'sync' && newState[name]) {
      const response = db
        .firestore()
        .collection('accounts')
        .doc(user.email)
        .get()

      const data = (await response).data()
      if (data) {
        onSync(data, user, true)
      }
    }
  }

  const fetchAccounts = async (actualUser: any) => {
    const response = db
      .firestore()
      .collection('accounts')
      .doc(actualUser.email)
      .get()

    const data = await (await response).data()
    if (data === undefined) {
      db.firestore()
        .collection('accounts')
        .doc(actualUser.email)
        .set({
          alertSetting: {
            enable: false,
            levelOne: [false, false],
            levelTwo: [false, false],
          },
          alerts: [],
          contributions: [],
          premium: false,
          savedPlaces: [],
        })
    }
    if (data !== undefined && data.premium) {
      userState.isPremium = true
    }
    if (data === undefined || data.premium === false)
      history.push('/becomePremium')
    if (data !== undefined)
      setUserState({ isAuthenticating: false, isPremium: data.premium })
  }

  return (
    <Fragment>
      <style>{css}</style>
      <Row
        style={{
          minHeight: '100vh',
          borderLeft: 'solid 0.5px rgba(255,255,255,0.6)',
        }}
      >
        {!userState.isAuthenticating && userState.isPremium && (
          <Col xs={12} lg={6}>
            <Fragment>
              <div className="title-wrapper">
                <span>Notifications</span>
              </div>

              <div
                style={{
                  backgroundColor: SECTION_COLOR,
                  paddingLeft: 30,
                  paddingTop: 20,
                  paddingBottom: 5,
                }}
                className="alerts-wrapper"
              >
                <h5 style={{ color: WHITE_COLOR }}>Get Alerts</h5>
                <Row
                  style={{
                    minHeight: '20vh',
                    borderLeft: 'solid 0.5px rgba(255,255,255,0.6)',
                    border: 0,
                    paddingBottom: 20,
                    display: 'flex',
                  }}
                >
                  <Col xs={8} lg={6}>
                    <div style={{ color: TEXT_DARK_LIGHT, marginBottom: 15 }}>
                      When crowds reach Level 1
                    </div>
                    <ToggleSwitch
                      label={'l1Sound'}
                      text={'Send an email'}
                      checked={state.l1Sound}
                      onChange={handleChange}
                    />
                    <ToggleSwitch
                      label={'l1Pop'}
                      text={'Pop-up display'}
                      checked={state.l1Pop}
                      onChange={handleChange}
                    />

                    <div style={{ color: TEXT_DARK_LIGHT, marginBottom: 15 }}>
                      When crowds reach Level 5
                    </div>
                    <ToggleSwitch
                      label={'l5Sound'}
                      text={'Send an email'}
                      checked={state.l5Sound}
                      onChange={handleChange}
                    />
                    <ToggleSwitch
                      label={'l5Pop'}
                      text={'Pop-up display'}
                      checked={state.l5Pop}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={8} lg={6}>
                    <ToggleSwitch
                      label={'sync'}
                      text={'Sync to mobile'}
                      checked={state.sync}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
              <div
                className="alerts-wrapper"
                style={{
                  textAlign: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                  color: WHITE_COLOR,
                  marginBottom: 20,
                }}
              >
                You've chosen to get alerts when crowd levels change at these
                places.
              </div>
              <div>
                <div style={{ marginBottom: 10 }}>
                  {!_isEmpty(subscribedPlaces) && jsx.arr}
                  {_isEmpty(subscribedPlaces) && (
                    <div
                      className="empty-list-section"
                      style={{ fontSize: '15px' }}
                    >
                      No subscribed places to display
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          </Col>
        )}

        {!userState.isAuthenticating && !user && (
          <Col xs={12} lg={6} className="payment-error-wrapper">
            <PaymentSuccess />
          </Col>
        )}

        <Col xs={12} lg={6}>
          {generateMap()}
        </Col>
      </Row>
    </Fragment>
  )
}

export default Alerts
