import { Minivenue} from "../types/foursquare";
import { sortBy as _sortBy } from "lodash";
import cookie from "react-cookies";

function removeVenueFromList(vlist: any, venue: any) {
    for(let i=0; i < vlist.length; i++) {
      if (vlist[i].id === venue.id) {
        vlist.splice(i, 1)
      }
    }
    return vlist
  }

function listContainVenue(vlist: any, venue: any) {
    for(let i=0; i < vlist.length; i++) {
      if (vlist[i].id === venue.id) {
        return true
      }
    }
    return false
  }


function convertGplacesToVenues(gplaces: Array<any>, originLat: number, originLng: number): Array<any> {
  let venues: Array<any> = [];
  gplaces.forEach((gplace: any) => {
    venues.push(convertGooglePlaceToMiniVenue(gplace, originLat, originLng))
  })
  return venues;
}
function convertGooglePlaceToMiniVenue(gplace: any, originLat: number, originLng: number) {
  const gplaceLocation = gplace.geometry.location
  const venueLat = gplaceLocation.lat;
  const venueLng = gplaceLocation.lng;
  let calculatedDistance = calculateDistance(
    { lat: originLat, lng: originLng },
    { lat: venueLat, lng: venueLng}
  )
  let venueCategories: Array<any> = [];
  gplace.types.forEach((type: string) => {
    venueCategories.push({
      id: "",
      name: type,
      pluralName: type,
      shortName: type,
      icon: {
        prefix: gplace.icon,
        suffix: ""
      },
      primary: false
    })
  })
  const venue: any = {
    id: gplace.place_id,
    name: gplace.name,
    location: {
      lat: venueLat,
      lng: venueLng,
      distance: calculatedDistance,
      address: gplace.vicinity,
      city: "",
      state: "",
      postalCode: "",
      country: "",
      crossStreet: "",
      formattedAddress: []
    },
    categories: venueCategories,
  }
  return venue
}
function formatAddress(venue: any): string {
  return `${venue.location.address || ""} ${venue.location.city || ""} ${venue.location.state || ""
    } ${venue.location.postalCode || ""}`;
}

function formatDistance(distance: number): string {
  let miles = (distance / 1609).toLocaleString(undefined, { maximumFractionDigits: 2 })
  let kmeters = (distance / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })

  switch (cookie.load('country_code')) {
    case 'US':
      return miles + " mi";
    default:
      return kmeters + " km";
  }
}

function timeConversionSlicker(s: any) {
  let AMPM = s.slice(-2);
  let timeArr = s.slice(':')[0].slice(0, -2);
  if (AMPM === "AM" && timeArr === "12") {
    // catching edge-case of 12AM
    timeArr = 0;
  } else if (AMPM === "PM") {
    // everything with PM can just be mod'd and added with 12 - the max will be 23
    timeArr = (timeArr % 12) + 12
  }
  return timeArr
}

function formatFoursquareDate(): string {
  const date = new Date();
  const year = `${date.getFullYear()}`;
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${year}${month}${day}`
}

function sortByVenueDistance(venues: Minivenue[]): Minivenue[] {
  const sortedVenues = _sortBy(venues, [function (o) { return o.location.distance }]);
  return sortedVenues;
}

function calculateDistance(posA: any, posB: any) {
  var R = 6371; // Radius of the Earth in miles
  var lat1 = posA.lat * Math.PI / 180; // Convert degrees to radians
  var lat2 = posB.lat * Math.PI / 180; // Convert degrees to radians
  var lon1 = posA.lng * Math.PI / 180; // Convert degrees to radians
  var lon2 = posB.lng * Math.PI / 180; // Convert degrees to radians
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = (lat2 - lat1);
  var d = Math.sqrt(x * x + y * y) * R;
  return d * 1000;
}

function dayNameToNumber(name:string){
  const days  = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  return days.indexOf(name.toLowerCase());
}

function checkLineOutside(lineOutsideHistory: any, currentVenueId: string, threshold: number) {
  if (currentVenueId in lineOutsideHistory) {
    return Math.floor((Date.now() - lineOutsideHistory[currentVenueId])/1000) < threshold
  }
  return false
}

export {
  formatAddress, formatDistance, formatFoursquareDate, calculateDistance, sortByVenueDistance, 
  timeConversionSlicker, convertGooglePlaceToMiniVenue, convertGplacesToVenues, dayNameToNumber,
  checkLineOutside, listContainVenue, removeVenueFromList
};
