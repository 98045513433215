import React from "react";

function BellIcon({
  color = "#FFF",
  width = "24px",
  height = "30px",
  opacity = "0.6",
  style,
  onClick,
}: {
  color?: string;
  width?: string;
  height?: string;
  opacity?: string;
  style?:object;
  onClick: (e : any)=>void;
}) {


  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      
      onClick={(e) => onClick(e)}
    >
      <path
        d="M9.90869 3.71953C9.90869 3.71953 10.351 1 11.9892 1C13.6273 1 14.1026 3.71953 14.1026 3.71953"
        stroke="white"
        strokeWidth="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.77539 21.3664C3.00365 19.8254 2.98004 19.6764 3.28623 18.4791C3.59242 17.2817 2.89278 9.48769 5.21961 7.01675C7.54643 4.54581 9.14861 3.71953 12.0057 3.71953C14.8629 3.71953 17.0857 4.85528 18.7544 7.01675C20.423 9.17822 20.475 16.8965 20.8554 18.4791C21.2357 20.0617 21.1805 19.633 22.3879 21.5603C23.5952 23.4876 22.6727 24.432 21.8637 24.752C21.0548 25.0721 12.0057 26.0388 12.0057 26.0388C12.0057 26.0388 3.29392 25.3046 2.08397 24.752C0.874007 24.1994 0.547128 22.9074 1.77539 21.3664Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M8.62622 26.0388C8.62622 26.0388 10.3539 28.7504 11.9846 28.7504C13.6153 28.7504 15.1814 26.0388 15.1814 26.0388"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default BellIcon;
