import React from "react";

function Person({
  color = "#D0D0D0",
  width = "20%",
	height = "35px",
	handleHoverOn,
	handleHoverOff,
	handleClick,
}: {
  color?: string;
  width?: string;
	height?: string;
	handleHoverOn?: any;
	handleHoverOff?: any;
	handleClick?: any;
}) {

  return (
	<svg id="person" 
		style={{cursor: 'pointer'}}
		onMouseOver={handleHoverOn}
		onMouseLeave={handleHoverOff}
		onClick={handleClick}
		width={width} 
		height={height}
		viewBox="0 0 13 32" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" 
			d="M6.39887 5.6298C7.84165 5.6298 9.01125 4.4602 9.01125 3.01741C9.01125 
			1.57463 7.84165 0.405029 6.39887 0.405029C4.95609 0.405029 3.78648 1.57463 
			3.78648 3.01741C3.78648 4.4602 4.95609 5.6298 6.39887 5.6298Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" 
			d="M1.42537 17.5012C0.234099 17.5012 0 16.8651 0 14.7776C0 12.6902 0.20713 
			10.8075 1.99683 8.7009C3.78653 6.59425 5.48705 6.7653 6.39891 6.7653C7.31077 
			6.7653 9.80787 6.72691 11.4034 9.15865C12.999 11.5904 12.9161 13.2366 12.9195 
			14.7776C12.923 16.3187 12.923 17.5012 11.737 17.5012C10.5511 17.5012 10.3387 
			16.5711 10.3387 16.5711C10.3387 16.5711 10.5657 12.5093 9.58033 11.1326C9.39338 
			16.2538 9.85269 29.8856 9.85269 29.8856C9.85269 29.8856 9.85269 31.3803 8.34119 
			31.3803C6.82969 31.3803 6.77612 29.8856 6.77612 29.8856V19.8855H6.20636L5.97979 
			29.8856C5.97979 29.8856 5.97979 31.3803 4.55418 31.3803C3.12857 31.3803 3.04171 
			29.8856 3.04171 29.8856C3.04171 29.8856 3.48156 17.0381 3.28431 11.4187C2.28669 
			13.4703 2.54836 15.8304 2.54836 15.8304C2.54836 15.8304 2.61664 17.5012 1.42537 
			17.5012Z" fill={color}/>
	</svg>
  );
}

export default Person;