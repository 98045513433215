import React from "react";

function CrowdIndex({
  width = "36px",
  height = "36px",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 29 27"
      fill="none"
    >
      <rect
        x="1"
        y="10.5144"
        width="7.13582"
        height="15.0645"
        rx="2"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.5"
      />
      <rect
        x="11.3076"
        y="4.96436"
        width="7.13582"
        height="20.6146"
        rx="2"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.5"
      />
      <rect
        x="20.8218"
        y="1"
        width="7.13582"
        height="24.5789"
        rx="2"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default CrowdIndex;
