import { SavedPlace } from "../types/savedplace";
const foursquare2saved = (venue: any): SavedPlace => {
    const category = venue.categories[0]
    const savedPlace = {
        id: venue.id,
        name: venue.name,
        location: {
            lat: venue.location.lat, 
            lng: venue.location.lng,
            address: venue.location.address
        },
        icon: category.icon.prefix + "100" + category.icon.suffix,
        category: category.name
    }
    return savedPlace
}

const gplace2saved = (venue: any): SavedPlace => {
    const category = venue.categories[0]
    const savedPlace = {
        id: venue.id,
        name: venue.name,
        location: {
            lat: venue.location.lat,
            lng: venue.location.lng,
            address: venue.location.address
        },
        icon: category.icon.prefix,
        category: category.name
    }
    return savedPlace
}


const crowdAnalytics2saved = (venue: any): SavedPlace => {
    const category = venue.categories[0]
    const savedPlace = {
        id: venue.id,
        name: venue.name,
        location: {
            lat: venue.location.lat,
            lng: venue.location.lng,
            address: venue.location.formattedAddress.join()
        },
        icon: venue.icon,
        category: category.name
    }
    return savedPlace
}
export { foursquare2saved, gplace2saved, crowdAnalytics2saved }