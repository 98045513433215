import db from '../firebase';
import Lockr from "lockr";
import { SECTION_COLOR, SUBSECTION_COLOR, WHITE_COLOR, CROWD_MESSAGE, CROWD_COLORS, LocalStorage } from "../constants";
import { formatAddress, formatDistance, calculateDistance, listContainVenue, removeVenueFromList } from "../utilities/utilities";
import { crowdAnalytics2saved } from "../utilities/convertVenues";
import { makeAndHandleCreateAlert, makeAndHandleDeleteAlert } from '../utilities/serviceCalls';


const unsubscribreVenue = async (venue : any, user: any, sync : boolean) : Promise<any | undefined>=> {
    
    const playerId = Lockr.smembers(LocalStorage.PLAYERID)
    const subscribedPlaces = Lockr.smembers(LocalStorage.SUBSCRIBEDPLACES)
      const newSubscribedPlaces = removeVenueFromList(subscribedPlaces,venue)
      Lockr.set(LocalStorage.SUBSCRIBEDPLACES, newSubscribedPlaces)
      
      if(playerId){
        makeAndHandleDeleteAlert(user.email, venue.id)
      }
      if(sync){
        const response=db.firestore().collection('accounts').doc(user.email).get();
        const data=await (await response).data();
        if(data){
          var alerts = data.alerts
          removeVenueFromList(alerts, venue)
          db.firestore().collection('accounts').doc(user.email).update({
            alerts:alerts
          })
        }
      }

    return newSubscribedPlaces
}

const subscribreVenue = async(venue : any, user: any, sync : boolean)  : Promise<any | undefined>=> {
    const playerId = Lockr.smembers(LocalStorage.PLAYERID)
    Lockr.sadd(LocalStorage.SUBSCRIBEDPLACES, crowdAnalytics2saved(venue))
      const newSubscribedPlaces = Lockr.smembers(LocalStorage.SUBSCRIBEDPLACES)
      if(playerId){
        makeAndHandleCreateAlert(user.email, venue.id)
      }
      if(sync){
        const response=db.firestore().collection('accounts').doc(user.email).get();
        const data=await (await response).data();
        if(data){
          var alerts = data.alerts
          alerts.push(crowdAnalytics2saved(venue))
          db.firestore().collection('accounts').doc(user.email).update({
            alerts:alerts
          })
        }
      }
    return newSubscribedPlaces
}


const premiumUser = async (user : any) : Promise<any | undefined>=> {
    const response=db.firestore().collection('accounts').doc(user.email).get();
    const data=await (await response).data();

    return data
}

export { premiumUser, subscribreVenue, unsubscribreVenue, }