import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Tabs, Tab, InputGroup, FormControl, Form, Button  } from "react-bootstrap";
import { TEXT_DARK_LIGHT, RED_COLOR, WHITE_COLOR, SUBSECTION_COLOR, SECTION_COLOR, LocalStorage }  from "../constants"
import { isEmpty as _isEmpty } from "lodash";
import  ToggleSwitch  from "../components/ToggleSwitch"
import MapWrapper from "../components/Map/MapWrapper";
import {} from "../utilities/serviceCalls"
import { usePlaceState } from "../store/place/placeContext";
import { FORMERR } from "dns";
import { useGeoLocation } from "../hooks/useGeolocation";
import SubscribedCard from '../components/SubscribedCard';
import Lockr from "lockr";
import { useAuth } from "../provider/AuthProvider";


function BecomePremium() {
  const { placeState, dispatchPlaceState } = usePlaceState();
  const { latitude, longitude } = useGeoLocation();
  const origin = { lat: Number(latitude), lng: Number(longitude) };


  const generateMap = () => {
    if (!_isEmpty(placeState.selectedVenue)) {
      return (
        <MapWrapper
          venue={placeState.selectedVenue}
          height="100vh"
          markers={placeState.markers}
        />
      );
    }
  };
  

  return (
    <Fragment>
     
      <Row
        style={{
          minHeight: "100vh",
          borderLeft: "solid 0.5px rgba(255,255,255,0.6)"
        }}
      > 
        <Col xs={12} lg={6}> 
     
          <Fragment >
            <div className="title-wrapper">
              <span>Become Premium</span>
            </div>
            
    
          </Fragment>
         
        </Col>
        
        <Col xs={12} lg={6}>
          {generateMap()}
        </Col>
        
      </Row>
      
    </Fragment>
  );
}
export default BecomePremium;