import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { InputGroup, Button } from "react-bootstrap";
import { isEmpty as _isEmpty } from "lodash";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { GMAPS_API_KEY } from "../services/config";
import {
  RED_COLOR,
  SECTION_COLOR,
  SUBSECTION_COLOR,
  LocalStorage
} from "../constants";
import { usePlaceState } from "../store/place/placeContext";
import {
  makeAndHandleHistorical,
  makeAndHandleResultsRequest,
  makeAndHandleAutocompleteVenueRequest,
  makeAndHandleLocationServiceRequest,
  makeAndHandleGeocodeRequest
} from "../utilities/serviceCalls";
import { useResultsState } from "../store/results/resultsContext";
import { useGeoLocation } from "../hooks/useGeolocation";
import { sortByVenueDistance, listContainVenue, removeVenueFromList } from "../utilities/utilities";
import { crowdAnalytics2saved } from "../utilities/convertVenues";
import { RecentPlacesContext } from "../contexts";
import "../styles/index.css"
import Lockr from "lockr"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PlaceInput(prop : {
  buttonText: string,
  margin : number,
  height: string,
}
) {
  // Hooks
  const history = useHistory();
  const { latitude, longitude } = useGeoLocation();
  // Global State
  const { placeState, dispatchPlaceState } = usePlaceState();
  // eslint-disable-next-line
  const { resultsState, dispatchResultsState } = useResultsState();
  const [recentPlaces, setRecentPlaces] = React.useContext(RecentPlacesContext)
  const [recall, setRecall] = useState(true)
  const query = useQuery();
  let searchQuery = query.get("query");

  // Component State
  const [inputState, setInputState] = useState<{
    isLoading: boolean;
    options: any[];
    querySearch: string;
  }>({
    isLoading: false,
    options: [],
    querySearch: '',
  });

  // eslint-disable-next-line
  const handleQueryChange = (query: string, e: Event): void => {
    dispatchPlaceState({
      type: "SET_VENUE",
      payload: query,
    });
    setInputState({ ...inputState, querySearch: query });
  };

  // eslint-disable-next-line 
  const handleAutocompleteSearch = async (query: string): Promise<void> => {
    try {
      setInputState({ ...inputState, isLoading: true, querySearch: query });
      let options;
      // postLocation service
      await makeAndHandleLocationServiceRequest(latitude, longitude);

      // Foursquare service for direct venue results
      const autocompleteVenues = await makeAndHandleAutocompleteVenueRequest({
        venueQuery: query,
        latitude,
        longitude,
      });
      options = sortByVenueDistance(autocompleteVenues.response.minivenues);

      // If there are no venues, search by address
      if (_isEmpty(options)) {
        // Rentalios service to get coordinates based on address
        const addressLocation = await makeAndHandleGeocodeRequest({
          address: query,
          lat: latitude,
          lng: longitude,
        });
        let lat: string = addressLocation[0].location.lat
        let lng: string = addressLocation[0].location.lng

        const autocompleteVenues = await makeAndHandleAutocompleteVenueRequest({
          venueQuery: addressLocation[0].name,
          latitude: lat,
          longitude: lng,
        });

        options = sortByVenueDistance(autocompleteVenues.response.minivenues);
      }
      // console.log(options, 'options')

      setInputState({
        ...inputState,
        isLoading: false,
        options,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectedOption = async (venueSelected: any): Promise<void> => {
    let results;

    if (!_isEmpty(venueSelected)) {
      venueSelected.id = venueSelected.place_id
      venueSelected.name = venueSelected.structured_formatting.main_text
      venueSelected.address = venueSelected.structured_formatting.secondary_text
      results = await geocodeByPlaceId(venueSelected.id)
      results = await getLatLng(results[0])
      venueSelected.location = results
      venueSelected.location.formattedAddress = [
        venueSelected.structured_formatting.secondary_text
      ]
      console.log(venueSelected)

      dispatchPlaceState({
        type: "SET_VENUE",
        payload: venueSelected.structured_formatting.main_text,
      });
      dispatchPlaceState({
        type: "SET_SELECTED_VENUE",
        payload: venueSelected,
      });
      dispatchResultsState({
        type: "LOADING",
        payload: true,
      });


      results = await makeAndHandleResultsRequest(venueSelected);
      // ### The Following Doesn't Work Yet... distance is now dynamically calculated for selected Venue
      // let calculatedDistance = calculateDistance(
      //   { lat: latitude, lng: longitude },
      //   { lat: venueSelected.location.lat, lng: venueSelected.location.lng }
      // )
      // let distance = formatDistance(calculatedDistance)
      // results.venue.location.distance = distance

      dispatchResultsState({
        type: "SET_VENUES",
        payload: results,
      });
      dispatchResultsState({
        type: "LOADING",
        payload: false,
      });
      const recentPlace = crowdAnalytics2saved(results.venue)
      if (!listContainVenue(recentPlaces, recentPlace)) {
        Lockr.sadd(LocalStorage.RECENTPLACES, recentPlace)
        const newRecentPlaces = Lockr.smembers(LocalStorage.RECENTPLACES)
        setRecentPlaces(newRecentPlaces) // Update global state
      } else {
        let newRecentPlaces = removeVenueFromList(recentPlaces, recentPlace)
        newRecentPlaces.push(recentPlace)
        Lockr.set(LocalStorage.RECENTPLACES, newRecentPlaces)
        setRecentPlaces(newRecentPlaces) // Update global state
      }
      //crowd analytics
      history.push("/venue_details?venueId=" + venueSelected.id +
        '&name=' + venueSelected.name +
        '&lat=' + venueSelected.location.lat +
        '&lng=' + venueSelected.location.lng +
        '&query=' + inputState.querySearch);
    }
    results = await makeAndHandleHistorical({
      latitude: venueSelected.location.lat,
      longitude: venueSelected.location.lng,
      radius: 5000,
    })
    placeState.markers = []
    if (results) {
      results.data.forEach((historical: any) => {
        placeState.markers.push({
          name: historical.name,
          lat: historical.latitude,
          lng: historical.longitude,
          index: historical.index_level
        })
      })
    }
  };

  const executeResultsEstimation = async (e: any): Promise<void> => {
    if (e.type === "click") {
      e.preventDefault();
      const venues = await makeAndHandleGeocodeRequest({
        address: inputState.querySearch,
        lat: latitude,
        lng: longitude,
      });
      const suggestions = venues.map(convertVenueToSuggestion)
      setInputState({ ...inputState, options: suggestions })
    }
  };

  if (searchQuery && recall) {
    setRecall(false)
    setInputState({ ...inputState, querySearch: searchQuery });
    console.log(inputState.querySearch)
  }
  const convertVenueToSuggestion = (venue: any) => {
    const name = venue.name
    const id = venue.id
    const address = venue.location.formattedAddress
    return {
      "description": name,
      "matched_substrings": [],
      "place_id": id,
      "reference": id,
      "structured_formatting": {
        "main_text": name,
        "main_text_matched_substrings": [],
        "secondary_text": address
      },
      "terms": [],
      "types": venue.categories
    }
  }

  return (
    <Fragment>
    
      <InputGroup className="mb-5" style={{ display: 'flex'}}>
        <div style={{  backgroundColor:SUBSECTION_COLOR, flexGrow:1,marginRight:prop.margin}}>
          <GooglePlacesAutocomplete
            placeholder="Search for a venue"
            apiKey={GMAPS_API_KEY}
            onSelect={(venue) => handleSelectedOption(venue)}
            withSessionToken
            
            loader={<div>Searching...</div>}
            initialValue={inputState.querySearch}
            autocompletionRequest={{
              radius: 10000,
              location: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
              types: ['establishment']
            }}
            renderInput={(props) => {
              var { onChange, ...others } = props;
              return <input
                className="rbt-input-main form-control rbt-input"
                placeholder="Search for a venue"
                style={{borderRadius:0, backgroundColor:SUBSECTION_COLOR, border:0, color:"white", height:prop.height}}
                onChange={(event) => {
                  onChange(event)
                  setInputState({ ...inputState, querySearch: event.target.value, options: [] })
                }}
                {...others}
              />
            }}
            renderSuggestions={(active, suggestionsFromAutoComplete, onSelectSuggestion) => {
              var suggestions = []
              if (Array.isArray(inputState.options) && inputState.options.length) {
                suggestions = inputState.options
              } else {
                suggestions = suggestionsFromAutoComplete
              }
          
              return (
                <ul className="rbt-menu dropdown-menu show" style={{ padding: 0, backgroundColor :SUBSECTION_COLOR,  border:0, borderRadius:0, width:"100%"}}>
                  {
                    suggestions.map((suggestion, i) => (
                      <li key={'async-venue-search-item-' + i}
                        className="suggestion"
                        onClick={(event) => onSelectSuggestion(suggestion, event)}
                      >                        
                        <a className="dropdown-item" href="/#">
                          <div className="justify-content-between align-items-center">
                            <div className="dropdown-item-title" >
                              {suggestion.structured_formatting.main_text}
                            </div>
                            <div style={{ fontSize: '10px', color: 'rgb(144, 144, 144)' }}>
                              {suggestion.structured_formatting.secondary_text}

                            </div>
                            {i !== suggestions.length-1 &&
                            <hr style={{marginBottom:0, marginTop:8,borderColor:"rgb(144, 144, 144)"}}></hr>
                            }
                          </div>
                        </a>
                      </li>
                    ))
                  }
                </ul>
              )
            }}
            
          />
        </div>

        <InputGroup.Append>
          <Button
            style={{ backgroundColor: RED_COLOR, borderRadius: '0px', borderColor: RED_COLOR, height:"100%", marginTop: 0, paddingLeft:35, paddingRight:35}}
            onClick={executeResultsEstimation}
          >
            {prop.buttonText}
          </Button>

        </InputGroup.Append>
      </InputGroup>
    </Fragment>
  );
}

export default PlaceInput;
