import React from 'react'

function CircleCrowdIndex() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
    >
      <rect
        x="5.65625"
        y="11.0381"
        width="2.42537"
        height="6.00122"
        rx="1"
        stroke="white"
      />
      <rect
        x="10.5295"
        y="8.82715"
        width="2.42537"
        height="8.21219"
        rx="1"
        stroke="white"
      />
      <rect
        x="15.4028"
        y="7.24805"
        width="2.42537"
        height="9.79146"
        rx="1"
        stroke="white"
      />
      <circle cx="11.312" cy="11.9614" r="10.812" stroke="white" />
    </svg>
  )
}

export default CircleCrowdIndex
