import React, { Fragment } from "react";
import "../../styles/mapArea.css";

interface IMarker {
  color?: string;
  lat: number;
  lng: number;
  radius: number;
}

function Area(props: IMarker) {
  return (
    <Fragment>
      <div
        className="circle"
        style={{ backgroundColor: props.color, opacity: '50%',
          width: props.radius, height: props.radius,
          top: -(props.radius/2), left: -(props.radius/2),
        }}
      />
    </Fragment>
  );
}

export default Area;
