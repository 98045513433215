import React, { createContext, useReducer, useMemo } from "react";
import { initialPlaceState, placeReducer } from "./placeReducer";
import { PlaceProviderProps, PlaceStateContextValue } from "./place";

export const PlaceContext = createContext<PlaceStateContextValue>({
  placeState: initialPlaceState,
  dispatchPlaceState: () => {},
});

export const PlaceProvider = (props: PlaceProviderProps): JSX.Element => {
  const [placeState, dispatchPlaceState] = useReducer(
    placeReducer,
    initialPlaceState
  );

  const value = useMemo(() => {
    return {
      placeState,
      dispatchPlaceState,
    };
  }, [placeState]);

  return (
    <PlaceContext.Provider value={value}>
      {props.children}
    </PlaceContext.Provider>
  );
};

export const usePlaceState = (): PlaceStateContextValue => {
  const context = React.useContext(PlaceContext);
  if (!context) {
    throw new Error("usePlaceState must be used within a PlaceContextProvider");
  }
  return context;
};
