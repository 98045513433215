import axios, { AxiosResponse } from "axios";
import { FOURSQUARE_CLIENT_ID, FOURSQUARE_CLIENT_SECRET } from "./config";

const BASE_URL = "https://api.foursquare.com";

export const fetchAutocompleteVenues = async (queryParams: {
  query: string;
  ll: string;
  v: string;
}): Promise<AxiosResponse> => {
  return await axios.get(`${BASE_URL}/v2/venues/suggestcompletion`, {
    params: {
      ...queryParams,
      limit: 15,
      client_id: FOURSQUARE_CLIENT_ID,
      client_secret: FOURSQUARE_CLIENT_SECRET,
    },
  });
};

/*export const fetchAutocompleteVenues = async (queryParams: {
  input: string;
  origin: string;
  location: string;
}): Promise<AxiosResponse> => {
  return await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json`, {
    params: {
      input: queryParams.input,
      types: 'establishment',
      origin: queryParams.origin,
      location: queryParams.location,
      radius: 10000,
      key: 'AIzaSyAXL8dcfvV6TkIDSTPv0j-2mFGIFmPVHvg',
      sessiontoken: '892d9652-ece5-4425-81ba-769e90c62174'
    },
  });
};*/

export const fetchSimilarVenues = async (queryParams: {
  id: string;
  ll: string;
  v: string;
}): Promise<AxiosResponse> => {
  return await axios.get(`${BASE_URL}/v2/venues/${queryParams.id}/similar`, {
    params: {
      ll: queryParams.ll,
      v: queryParams.v,
      limit: 5,
      client_id: FOURSQUARE_CLIENT_ID,
      client_secret: FOURSQUARE_CLIENT_SECRET,
    },
  });
};

export const fetchNearbyVenues = async (queryParams: {
  radius: number;
  ll: string;
  v: string;
}): Promise<AxiosResponse> => {
  return await axios.get(`${BASE_URL}/v2/venues/explore`, {
    params: {
      ...queryParams,
      limit: 5,
      client_id: FOURSQUARE_CLIENT_ID,
      client_secret: FOURSQUARE_CLIENT_SECRET,
    },
  });
};