import firebase from 'firebase';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { FirebaseAuth } from 'react-firebaseui';
import { auth } from '../firebase';

const AuthContext = createContext<any>(null);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {

    const [ user, setUser ] = useState<firebase.User | null>(null);
    
    function authUser() {
        return new Promise(function (resolve, reject) {
           firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                 resolve(user);
              } else {
                 reject('User not logged in');
              }             
           });
        });
     }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
        });

        return unsubscribe;
    }, [])

    const signup = (email: string, password: string) => {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    const sendEmailVerification = (newUser: firebase.User) => {
        return newUser.sendEmailVerification();
    }

    const loginEmailPassword = (email: string, password: string) => {
        return auth.signInWithEmailAndPassword(email, password);
    }

    const googleProvider = new firebase.auth.GoogleAuthProvider();
    const loginGoogle = () => {
        return auth.signInWithPopup(googleProvider);
    }

    /*const facebookProvider = new firebase.auth.FacebookAuthProvider();
    const loginFacebook = () => {
        return auth.signInWithPopup(facebookProvider);
    }*/

    const logout = () => auth.signOut();
    const value: any = { signup, loginEmailPassword, loginGoogle, logout, sendEmailVerification, user, authUser};

    return (
        <AuthContext.Provider value={value} >
            {children}
        </AuthContext.Provider>
    )
}