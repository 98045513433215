import React, { createContext, useReducer, useMemo } from "react";
import { initialResultsState, resultsReducer } from "./resultsReducer";
import { ResultsProviderProps, ResultsStateContextValue } from "./results";

export const ResultsContext = createContext<ResultsStateContextValue>({
  resultsState: initialResultsState,
  dispatchResultsState: () => {},
});

export const ResultsProvider = (props: ResultsProviderProps): JSX.Element => {
  const [resultsState, dispatchResultsState] = useReducer(
    resultsReducer,
    initialResultsState,
  );

  const value = useMemo(() => {
    return {
      resultsState,
      dispatchResultsState,
    };
  }, [resultsState]);

  return (
    <ResultsContext.Provider value={value}>
      {props.children}
    </ResultsContext.Provider>
  );
};

export const useResultsState = (): ResultsStateContextValue => {
  const context = React.useContext(ResultsContext);
  if (!context) {
    throw new Error(
      "useResultsState must be used within a ResultsContextProvider"
    );
  }
  return context;
};
