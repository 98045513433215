import React, { Fragment } from "react";
// import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { BrowserRouter, Route, Redirect, Switch} from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import { SavedPlacesContext, LineOutsideContext, RecentPlacesContext, SubscribedPlacesContext, AlertsConfigContext } from "./contexts"

import Home from "./pages/Home";
import Results from "./pages/Results";
import VenueDetails from "./pages/VenueDetails";
import SavedPlaces from "./pages/SavedPlaces";
import CrowdLevel from "./pages/crowd-level";
import Feedback from "./pages/Feedback";
import Alerts from "./pages/Alerts";
import BecomePremium from "./pages/BecomePremium"
import axios from "axios";
import cookie from "react-cookies";
import Locations from "./pages/dashboard/Locations";
import Lockr from "lockr";
// import Test from "./pages/Test";

const cookieMeta = {
  path: "/",
  domain:
    process.env.NODE_ENV === "development" ? "localhost" : "crowdalerts.com"
};

interface Location {
  asn: string
  city: string
  continent_code: string
  country: string
  country_area: number
  country_calling_code: string
  country_capital: string
  country_code: string
  country_code_iso3: string
  country_name: string
  country_population: number
  country_tld: string
  currency: string
  currency_name: string
  in_eu: boolean
  ip: string
  languages: string
  latitude: number
  longitude: number
  org: string
  postal: string
  region: string
  region_code: string
  timezone: string
  utc_offset: string
}

const fetchLocation = () => {
  return axios.get<Location>('https://ipapi.co/json/')
};

function App() {
  React.useEffect(function () {
    fetchLocation().then(
      response => {
        cookie.save("country_code", response.data.country_code, cookieMeta);
      }
    )    
  }, [])

  //let countryCodeAux = await countryCode()


  const lineOutsideHook = React.useState({});
  const savedPlacesHook = React.useState(Lockr.smembers("savedPlaces"));
  const SubscribedPlacesHook = React.useState(Lockr.smembers("subscribedPlaces"));
  const AlertsConfigHook = React.useState(Lockr.smembers("alertsConfiguration"));
  const recentPlacesHook = React.useState(Lockr.smembers("recentPlaces"));
  return (
    <RecentPlacesContext.Provider value={recentPlacesHook}>
      <SavedPlacesContext.Provider value={savedPlacesHook}>
        <LineOutsideContext.Provider value={lineOutsideHook}>
          <SubscribedPlacesContext.Provider value={SubscribedPlacesHook}>
            <AlertsConfigContext.Provider value={AlertsConfigHook}>
              <Fragment>
                <BrowserRouter>
                  <DefaultLayout>
                    <Switch>
                      <Route path="/" exact key="Home" component={Home} />
                      <Route path="/results" exact key="Results" component={Results} />
                      <Route path="/venue_details" exact key="VenueDetails" component={VenueDetails} />
                      <Route path="/crowd_index" exact key="CrowdIndex" component={CrowdLevel} />
                      <Route path="/savedPlaces" exact key="SavedPlaces" component={SavedPlaces} />
                      <Route path="/feedback" exact key="Feedback" component={Feedback} />
                      <Route path="/alerts" exact key="Alerts" component={Alerts} />
                      <Route path="/becomePremium" exact key="Bome" component={BecomePremium} />
                      {/* <Route path="/test" exact key="test" component={Test} /> */}
                      <Route path="/analytics/locations" exact key="Locations" component={Locations} />
                      <Redirect from="/*" to="/venue_details" />
                    </Switch>
                  </DefaultLayout>
                </BrowserRouter>
              </Fragment>
            </AlertsConfigContext.Provider>
          </SubscribedPlacesContext.Provider>
        </LineOutsideContext.Provider>
      </SavedPlacesContext.Provider>
    </RecentPlacesContext.Provider>
  );
}

export default App;
