import { PlaceReducerAction, PlaceState } from "./place.d";
import { Minivenue } from "../../types/foursquare";

export const initialPlaceState: PlaceState = {
  venue: "",
  selectedVenue: {} as Minivenue,
  similarVenues: {} as any,
  nearbyVenues: {} as any,
  markers: [] as any
};

export const placeReducer = (
  state: PlaceState = initialPlaceState,
  action: PlaceReducerAction
): PlaceState => {
  switch (action.type) {
    case "SET_VENUE":
      return {
        ...state,
        venue: action.payload,
      };
    case "SET_SELECTED_VENUE":
      return {
        ...state,
        selectedVenue: action.payload
      };
    case "SET_MARKERS":
      return {
        ...state,
        markers: action.payload
      };
    case "RESET_PLACE":
      return {
        ...initialPlaceState,
      };
    case "SET_SIMILAR_VENUES":
      return {
        ...state,
        similarVenues: action.payload
      };
    case "SET_NEARBY_VENUES":
      return {
        ...state,
        nearbyVenues: action.payload
      };
    default:
      return state;
  }
};
