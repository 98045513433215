import React from "react";
import heartEmpty from "../assets/images/heartEmpty.svg";
import heartFilled from "../assets/images/heartFilled.svg";

function HeartCheckBox(props: {

    onClick: (e: any) => void;
    checked: boolean;
}) {
    const { onClick, checked } = props;
    const imgSrc = checked ? heartFilled : heartEmpty;
    return (
        <img src={imgSrc} onClick={onClick} alt="HeartCheckBox" />
    )
}

export default HeartCheckBox;