import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/images/logo.svg";
import { BLACK_COLOR } from "../constants";
import { WHITE_COLOR } from "../constants";
import { useAuth } from "../provider/AuthProvider";
import firebase from 'firebase';

function NavBar(props: any) {

  const { user, logout } = useAuth();

  const [show, setShow] = useState(false);
  const showDropdown = (e: any) => {
    setShow(!show);
  }
  const hideDropdown = (e: any) => {
    setShow(false);
  }

  const handleLogout = async () => {
    try {
      await logout()
      console.log(user);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Navbar variant="dark" style={{ backgroundColor: BLACK_COLOR, borderBottom: 'solid 0.5px rgba(255,255,255,0.6)' }}>
      <Navbar.Brand href="https://www.crowdalerts.com">
        <img
          style={{ width: "30px", height: "30px" }}
          src={logo}
          alt="Crowd Alerts Logo"
        />{" "}
        Crowd Alerts
      </Navbar.Brand>
      <Navbar.Collapse>
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="https://www.crowdalerts.com" style={{ color: WHITE_COLOR, marginLeft:40, fontSize:16 }}>Home</Nav.Link>
          <Nav.Link href="https://www.crowdalerts.com/#about" style={{ color: WHITE_COLOR, marginLeft:40, fontSize:16   }}>About</Nav.Link>
          {user == null ?
            <Nav.Link onClick={props.togle} style={{ color: WHITE_COLOR, marginLeft:40, fontSize:16, marginRight:40  }}>Login</Nav.Link>
            :
            <NavDropdown
              id="user-nav-dropdown"
              title={<span className="nav-title">Welcome {firebase.auth().currentUser?.email} </span>}
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              style={{marginLeft:40, fontSize:16, marginRight:40}}
            >
              <NavDropdown.Item className="drop-item" onClick={handleLogout}>Log Out</NavDropdown.Item>
            </NavDropdown>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
