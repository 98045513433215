import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Background from "../components/Background";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import LoginModal from "../components/Modal/Login";
import OneSignal from 'react-onesignal';
import { makeAndHandleRegisterUser } from '../utilities/serviceCalls'
import { WHITE_COLOR, LocalStorage } from "../constants";
import Lockr from "lockr";


function DefaultLayout(props: { children: React.ReactNode }) {
  const location = useLocation();
  let paddingLeft = location.pathname !== "/" ? '90px' : '';

  // Modal states and handlers for showing
  const [modalState, setModalState] = useState({show: false});
  const showModalHandler = () => {
    setModalState(previousState => ({ show: true }))
  }
  const hideModalHandler = () => {
    setModalState(previousState => ({ show: false }))
  }
  
  useEffect(() => {
    

    OneSignal.init({
        appId: "3d0ec1e0-e080-4168-8082-ad032ca7c2b5",
        subdomainName:"www",
        notifyButton: {
          enable: true,
        },
    });
    OneSignal.getUserId( function(userId) {
      if(userId){
        Lockr.set(LocalStorage.PLAYERID, userId)
      }
        
    });
}, []);

  return (
    <Fragment>
      <Background isBackgroundActive={location.pathname === "/"}>
        <NavBar togle={showModalHandler} />
        <div style={{ position: 'absolute', display: 'contents' }}>
          {location.pathname !== "/" ? <SideBar /> : null}
          <Container fluid
            style={{ paddingLeft: paddingLeft }}
          >
            {props.children}
          </Container>
        </div>
      </Background>
      <LoginModal show={modalState.show} onHide={hideModalHandler}></LoginModal>
    </Fragment>
  );
}

export default DefaultLayout;
