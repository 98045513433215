import React from "react";

function ArrowRight() {

  return (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12L6 6.26087L1 1" stroke="white" strokeOpacity="0.87"/>
    </svg>
  );
}

export default ArrowRight;