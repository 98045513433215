import React, { useState, useEffect } from 'react'
import superCluster from 'use-supercluster'
import GoogleMapReact from 'google-map-react'
import Marker from '../../components/Map/Marker'
import './Locations.css'
import { LocationResponse, LocationLocations } from '../../types/locations'
import { GMAPS_API_KEY } from '../../services/config'
import { style } from '../../assets/images/mapStyle/darkModeMap'
import { makeAndHandleLocationsDashboard } from '../../utilities/serviceCalls'
//import { DateRangePicker } from 'rsuite'
//import 'rsuite/dist/styles/rsuite-default.css'
import 'react-dates/initialize';
// import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const DashboardMarker = ({children}: any) => children
export default function Locations() {        
    //Constants    
    let i = 0
    const defaultZoom = 0    
    const coords ={
        lat: 25.588203,
        lng: -100.248369
    }    
        
    // Google map states
    const [globalMap, setGlobalMap] = useState(Object)
    const [zoom, setZoom] = useState(0)
    const [bounds, setBounds] = useState([] as number[])    
    const [defaultCenter, setDefaultCenter] = useState({
        lat: 37.360825, // 52.6376,
        lng: -121.949902 //-1.135171//-121.949902
    })

    // Data states
    const [locationsData, setLocationsData] = useState<LocationResponse>()        
    const [dates, setDates] = useState([new Date(), new Date()])        
    const [realTime, setRealTime] = useState(false)
    const [loading, setLoading] = useState(true)    
    
    // Functions
    const requestLocations = async (): Promise<void> => {                                           
        // Request navigator location
        navigator.geolocation.getCurrentPosition(async (position) =>  {                     
            // Successfully obtained user location        
            // Set center of Google map
            setDefaultCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
                  
            // Parameters for post request
            const params = {
                latitude: position.coords.latitude,
                longitde: position.coords.longitude,                
                startDate: parseDate(dates[0]),
                endDate: parseDate(dates[1]),
                realtime: realTime     
            }
            
            // Send request and wait for result
            const results = await makeAndHandleLocationsDashboard(params)                                                              

            // Loading to false and display results
            setLoading(false)
            setLocationsData(results)                       
        }, async () => {
            // Unsuccessful getting user's location and using a default location
            // Parameters for post request
            const params = {
                latitude: coords.lat,
                longitde: coords.lng,                
                startDate: parseDate(dates[0]),
                endDate: parseDate(dates[1]),
                realtime: realTime
            }
            
            // Send request and wait for result
            const results = await makeAndHandleLocationsDashboard(params)                                                   

            // Loading to false and display results
            setLoading(false)
            setLocationsData(results)   
        }, { /* 10 seconds timer for getting user's location */ timeout: 7000})                                    
    }
    
    const toggleStartDate = (event: any) => {        
        const selectedDay = new Date(event.target.value)
        selectedDay.setDate(selectedDay.getDate() + 1)                
        const newDates = [selectedDay, dates[1]]
        
        setRealTime(false)
        setDates(newDates)        
    }

    const toggleEndDate = (event: any) => {                       
        const selectedDay = new Date(event.target.value)
        selectedDay.setDate(selectedDay.getDate() + 1)                
        const newDates = [dates[0], selectedDay]
        
        setRealTime(false)
        setDates(newDates)        
    }

    const toggleRealTime = (event: any) => {
        const text = event.target.value
        const flag = (text[0] === 'E') ? true : false
                                
        setRealTime(flag)
        if (flag) {
            const now = new Date()
            const newDates = [now, now]// [oneHourBefore, now]            
            
            setDates(newDates)
        }
    }    

    const parseDate = (date: Date) => {        
        const year = (date.getFullYear()).toString()
        let month = (date.getMonth()+1).toString()
        let day = (date.getDate()).toString()
        
        if (day.length < 2) {
            day = `0${day}`
        }
        
        if (month.length < 2) {
            month = `0${month}`
        }

        return `${year}-${month}-${day}`
    }

    //Effects
    useEffect(() => {        
        setLoading(true)
        requestLocations()
        // eslint-disable-next-line
    }, [dates])

    // Add properties for clustering
    const locations = locationsData ? locationsData.locations : [] 
    const points = locations.map((location: LocationLocations) => ({
        "type": "Feature",
        "properties": {
            "cluster": false,
            "deviceId": `${location.deviceId}-${location.latitude}-${location.longitude}-${++i}`,
            "source": location.source
        },
        "geometry": { 
            "type": "Point", 
            "coordinates": [
                location.longitude,
                location.latitude
            ]    
        }        
    }))
                
    // get clusters 
    const { clusters, supercluster } = superCluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 16  }
    })

    // When we are calling the API we show Loading message
    if (loading) return (
        <h1 style={{color: 'white'}}>Loading...</h1>
    )
           
    // When data is received and ended loading
    return (                 
        <div style={{height: '85vh', width: '100%'}}>          
            <div id='filters'>        
                <input 
                    type="date" 
                    name="startDate" 
                    id="startDate"
                    value={parseDate(dates[0])}
                    max={parseDate(new Date())}
                    onChange={toggleStartDate}                                        
                />
                <input 
                    type="date" 
                    name="startDate" 
                    id="startDate"
                    value={parseDate(dates[1])}
                    min={parseDate(dates[0])}
                    max={parseDate(new Date())}
                    onChange={toggleEndDate}                                        
                />                                
                <input 
                    type="button" 
                    value={(realTime) ? "Disable Real time" : "Enable Real Time"}                    
                    id={(realTime) ? "realTimeButton-click" : "realTimeButton"}
                    autoFocus={realTime}
                    onClick={toggleRealTime}
                />
            </div>           
            <GoogleMapReact
                bootstrapURLKeys={{ key: GMAPS_API_KEY, language: "en" }}
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}                
                center={defaultCenter}
                zoom={defaultZoom}
                hoverDistance={40 / 2}                
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map}) => {    
                    setGlobalMap(map)                                                           
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom)
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ])
                }}
                options={{
                    styles: style,
                    clickableIcons: false
                }}
            >     

            {clusters.map((cluster: any) => {
                const [lng, lat] = cluster.geometry.coordinates
                const {cluster: isCluster, point_count: pointCount} = cluster.properties

                if (isCluster) {
                    return (
                        <DashboardMarker key={cluster.id} lat={lat} lng={lng} >
                            <div className="cluster-marker" style={{
                                width: `${10 + (pointCount / points.length) * 40}px`,
                                height: `${10 + (pointCount / points.length) * 40}px`
                            }}
                            onClick={() => {
                                const expansionZoom = Math.min(                                    
                                    supercluster.getClusterExpansionZoom(cluster.id),
                                    16
                                )                
                                
                                globalMap.setZoom(expansionZoom)                                
                                globalMap.panTo({lat: lat, lng: lng})
                                
                            }}
                            >{pointCount}</div>                            
                        </DashboardMarker>                                                    
                    )
                }
                
                return (
                    <Marker 
                        key={cluster.properties.deviceId}                    
                        lat={lat}
                        lng={lng}
                        color="darkorange"                                         
                    />                                            
                )
            })}          
            </GoogleMapReact>
        </div>                         
    )
}
