import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Tabs, Tab, InputGroup, FormControl, Form, Button, Modal  } from "react-bootstrap";
import { isEmpty as _isEmpty } from "lodash";
import { TEXT_DARK_LIGHT, RED_COLOR, WHITE_COLOR, SUBSECTION_COLOR, SECTION_COLOR }  from "../constants"
import MapWrapper from "../components/Map/MapWrapper";
import { useFeedbackForm } from '../hooks/useFeedbackForm';
import { usePlaceState } from "../store/place/placeContext";
import { FORMERR } from "dns";
import { FeedbackForm } from '../types/feedback'
import { makeAndHandleFeedbackPost } from '../utilities/serviceCalls'
import thanks_tara from "../assets/images/thanks_tara.svg";

function Feedback() {
  const { placeState, dispatchPlaceState } = usePlaceState();
  const [ state, setState ] = useState<FeedbackForm>({email:'', text:''});
  const [ file, setFile ] = useState<File>();
  const [feedbackNotif, showFeedbackNotif] = useState<{ show: boolean; }>({ show: false, });

     
  const generateMap = () => {
    if (!_isEmpty(placeState.selectedVenue)) {
      return (
        <MapWrapper
          venue={placeState.selectedVenue}
          height="100vh"
          markers={placeState.markers}
        />
      );
    }
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.currentTarget.files){ 
      setFile( e.currentTarget.files[0]);
    }
    
  }

  const handleCloseSuccess = () => {
    showFeedbackNotif({ show: false })
  }

const onSubmit = async () => {
    const results = await makeAndHandleFeedbackPost (
      feedback.email,
      feedback.text,
      file
    )
    console.log(results)
    showFeedbackNotif({ show: true })
    setFile(undefined)
    resetState(state)

}

const { handleSubmit, handleChange,resetState, data: feedback, errors } = useFeedbackForm<FeedbackForm>(
  
  {

  initialValues: state,
  validations: {
    email: {
      patternOptional: {
        value: "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?!-)(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$",
        message:
          "Please write a valid email",
      },
    },
    text: {
      custom: {
        isValid: (value) => value.length > 1,
        message:
          "A message is needed for your feedback",
      },
    },

  },
  onSubmit: onSubmit,
});

  return (
    <Fragment>
      <Row
        style={{
          minHeight: "100vh",
          borderLeft: "solid 0.5px rgba(255,255,255,0.6)"
        }}
      >
        <Col xs={12} lg={6}>
          <Fragment>
            <div className="title-wrapper">
              <span>Give Us Your Feedback!</span>
            </div>
            <div style={{backgroundColor: SECTION_COLOR, padding:10}}>
            <Form > 
              <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Label style={{ color:WHITE_COLOR}}>Email address</Form.Label>
                <Form.Control type="email" value={feedback.email } style={{backgroundColor: SUBSECTION_COLOR,  border:0, color: WHITE_COLOR, borderRadius:0}} onChange={handleChange('email')}/>
                {errors.email && <p className="text-muted">{errors.email}</p>}

              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ color:WHITE_COLOR}}>Let us know what you like or if you are having a problem with Crowd Alerts. Your feedback is appreciated and helps us improve the experience.</Form.Label>
                <Form.Control as="textarea" value={feedback.text} rows={6} style={{backgroundColor: SUBSECTION_COLOR, color: WHITE_COLOR,  border:0, borderRadius:0}} onChange={handleChange('text')}/>
                {errors.text && <p className="text-muted">{errors.text}</p>}
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-5" style={{color: WHITE_COLOR}}>
              <Form.Label style={{ color:WHITE_COLOR}}>Send an image</Form.Label>
                <Form.Control type="file" accept=".png,.jpeg,.jpg" onChange={onFileChange}/>
              </Form.Group>
              <Button variant="primary" className='mt-5'  style={{backgroundColor: RED_COLOR, width:'100%', border:0, borderRadius:0}} onClick={handleSubmit} >
                Submit
              </Button>
            </Form>
            </div>
          </Fragment>
        </Col>
        <Col xs={12} lg={6}>
          {generateMap()}
        </Col>
      </Row>
      <Modal show={feedbackNotif.show} onHide={handleCloseSuccess} style={{ textAlign: 'center' }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ display: 'block', background: '#222222', margin: '10px'}}>
          <img src={thanks_tara} alt="" width="150px" className="pb-3" />
          <div>Thanks for your feedback!</div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'contents'}}>
          <Button style={{margin: '12px 0 26px 0' }} variant="danger" onClick={handleCloseSuccess}>
            Close
					</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default Feedback;