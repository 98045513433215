import axios, { AxiosResponse } from "axios";

const BASE_URL = "https://crowd-alerts.wl.r.appspot.com";
const searchRadius = "5000";

export const fetchSimilarVenues = async (queryParams: {
  id: string;
  ll: string;
  v: string;
  venueType: string;
}): Promise<AxiosResponse> => {
  let requestParams = {
    location: queryParams.ll,
    radius: searchRadius,
  }
  let venueType = queryParams.venueType;
  Object.assign(requestParams,
    (venueType !== null) && { type: venueType.toLowerCase() },
    (venueType !== null) && { keyword: venueType.toLowerCase() }
  )
  return await axios.get(`${BASE_URL}/nearbysearch`, {
    params: requestParams
  });
}