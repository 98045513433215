import React, { Fragment } from "react";
import "../../styles/mapMarker.css";

interface IMarker {
  color?: string;
  lat: number;
  lng: number;
  title?: string;
}

function Marker(props: IMarker) {
  return (
    <Fragment>
      <div
        className="pin bounce"
        style={{ backgroundColor: props.color, cursor: "pointer" }}
        title={props.title}
      />
      <div>{props.title}</div>

      <div className="pulse" />
    </Fragment>
  );
}

export default Marker;
