import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAKWzf_AoEQtb8FG65VlEfifUkSUzMxHSA",
  authDomain: "crowd-alerts.firebaseapp.com",
  databaseURL: "https://crowd-alerts.firebaseio.com",
  projectId: "crowd-alerts",
  storageBucket: "crowd-alerts.appspot.com",
  messagingSenderId: "108543205301",
  appId: "1:108543205301:web:32503583f89d8a987503fc",
  measurementId: "G-53B6QGRN6M"
};

firebase.initializeApp(firebaseConfig);
const db=firebase;

export default db;
export const auth = firebase.auth();