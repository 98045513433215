import React from 'react'

function CircleHeart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4178 8.67706C11.4178 8.67706 10.3745 5.39697 7.80954 5.67857C5.2446 5.96018 3.92481 8.67706 5.21405 10.6027C6.29542 12.7691 11.4178 16.9683 11.4178 16.9683C11.4178 16.9683 16.6681 12.4425 17.4923 10.6027C18.5418 8.67706 17.578 5.96058 15.0394 5.67857C12.5009 5.39657 11.4178 8.67706 11.4178 8.67706Z"
        stroke="white"
        stroke-linejoin="round"
      />
      <circle cx="11.312" cy="11.312" r="10.812" stroke="white" />
    </svg>
  )
}

export default CircleHeart
