import React from 'react'

function CircleNoAds() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <g clip-path="url(#clip0_435_8575)">
        <path
          d="M17.0447 8.45245C18.0257 7.6511 17.6869 6.06637 16.4609 5.73794C16.1406 5.65212 15.809 5.66997 15.505 5.78576L14.531 4.09863C14.1346 3.41206 13.1515 3.39387 12.7295 4.06328C11.5314 5.96356 9.93528 7.57977 8.04034 8.80624C7.93244 8.87594 7.89244 9.01998 7.95629 9.13722C7.96653 9.15598 9.57998 11.9502 9.59066 11.9687C9.66005 12.0889 9.8137 12.1302 9.93405 12.0607C10.0543 11.9913 10.0955 11.8376 10.0261 11.7173L8.51304 9.09666C10.3199 7.88875 11.9202 6.2896 13.1548 4.33141C13.3756 3.98114 13.8888 3.99201 14.0955 4.35001L17.5834 10.3911C17.7903 10.7496 17.5422 11.1991 17.1291 11.2152C14.8114 11.3055 12.5423 11.8983 10.4676 12.9594C10.4588 12.9639 8.10658 14.3221 7.9446 14.4156C7.52413 14.6584 6.98459 14.5138 6.74188 14.0934L5.46858 11.8879C5.22584 11.4674 5.37042 10.9279 5.79086 10.6852L7.32333 9.80038C7.44356 9.73096 7.48475 9.57721 7.41534 9.45698C7.34592 9.33673 7.19214 9.29556 7.07195 9.36498L5.53947 10.2498C4.87896 10.6311 4.65184 11.4787 5.03319 12.1393L6.30651 14.3447C6.68789 15.0053 7.53553 15.2324 8.19604 14.851L8.25402 14.8175L9.82609 17.5404C10.0519 17.9316 10.5512 18.0655 10.9425 17.8396L11.4939 17.5213C11.8851 17.2954 12.0189 16.7961 11.7931 16.4048L10.6789 14.475C10.6095 14.3548 10.4557 14.3136 10.3355 14.383C10.2153 14.4524 10.1741 14.6062 10.2435 14.7264L11.3577 16.6562C11.4446 16.8067 11.3931 16.9989 11.2425 17.0858L10.6912 17.4042C10.5406 17.4911 10.3484 17.4396 10.2615 17.2891L8.68945 14.5662L10.7023 13.404C12.6719 12.3977 14.8631 11.8066 17.1487 11.7175C17.9408 11.6866 18.4146 10.8251 18.0188 10.1397L17.0447 8.45245ZM16.9729 6.7162C17.221 7.14607 17.1319 7.67951 16.7883 8.00847L15.7613 6.22952C16.2115 6.09745 16.7207 6.27939 16.9729 6.7162Z"
          fill="white"
          stroke="white"
          stroke-width="0.5"
        />
      </g>
      <circle cx="11.312" cy="11.6109" r="10.812" stroke="white" />
      <defs>
        <clipPath id="clip0_435_8575">
          <rect
            width="15.0827"
            height="16.16"
            fill="white"
            transform="translate(3.77075 3.12695)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircleNoAds
