import axios, { AxiosResponse } from "axios";

const getLocationsURL = 'https://crowd-alerts.wl.r.appspot.com/getLocations'
const headers = {
    "Content-Type": "application/json",
}

export const fetchAllLocations = async ({
  latitude,
  longitude,
  radius,
  startDate = "",
  endDate = "",
  realtime = false,
  boundingBox = []    
  }: {
    latitude: number,
    longitude: number,
    radius?: number;
    startDate?: string,
    endDate?: string,    
    realtime?: boolean,
    boundingBox?: number[]
  }): Promise<AxiosResponse> => {
    boundingBox = (boundingBox.length === 0) ? null as any : boundingBox    
    startDate = (startDate.length === 0) ? null as any : startDate
    endDate = (endDate.length === 0) ? null as any : endDate    
    return await axios.post(
      getLocationsURL,
      {
        latitude,
        longitude,
        radius,
        startDate,        
        endDate,          
        realtime,    
        boundingBox
      },
      { headers }
    );
};

