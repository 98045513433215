import React, { Fragment } from 'react'
import thanks_tara from '../assets/images/thanks_tara.svg'
import CircleHeart from '../components/icons/CircleHeart'
import CircleCrowdIndex from '../components/icons/CircleCrowdIndex'
import CircleNoAds from '../components/icons/CircleNoAds'
import CircleCrowdHistory from '../components/icons/CircleCrowdHistory'

function PaymentSuccess() {
  const css = `
      .image-wrapper {
          background-color: #5A9D62;
      }
      .close-button {
          background-color: #AB2131;
          border-radius: 2px;
          width: 301px;
          padding: 6px;
      }
      .white-color {
          color: #FFFFFF;
      }
      .wrapper {
        max-width: 50%;
      }
      .info-wrapper {
          padding: 33px 0;
          font-size: 17px;
          font-weight: 600;
      }`
  return (
    <div className="wrapper">
      <style>{css}</style>
      <div className="image-wrapper text-center">
        <img src={thanks_tara} className="" alt="sad-tara" />
      </div>
      <div className="info-wrapper text-center white-color">
        <div>Congratulations!</div>
        <div>
          You are now a Premium member, you can now enjoy this awesome features:
        </div>
      </div>
      <div className="info-wrapper white-color d-flex justify-content-center">
        <div>
          <div className="mb-2">
            <CircleHeart />
            <span className="ml-2">Saved Places</span>
          </div>
          <div className="mb-2">
            <CircleCrowdIndex />
            <span className="ml-2">Crowd Level Alerts</span>
          </div>
          <div className="mb-2">
            <CircleNoAds />
            <span className="ml-2">No Ads</span>
          </div>
          <div className="mb-2">
            <CircleCrowdHistory />
            <span className="ml-2"> Crowd Rating History</span>
          </div>
        </div>
      </div>
      <div className="button-wrapper text-center">
        <button className="close-button white-color">Close</button>
      </div>
    </div>
  )
}

export default PaymentSuccess
