import { ResultsReducerAction, ResultsState } from "./results.d";
import { ResultsResponse } from "../../types/api";

export const initialResultsState: ResultsState = {
  venues: {} as ResultsResponse
};

export const resultsReducer = (
  state: ResultsState = initialResultsState,
  action: ResultsReducerAction, 
  loading?: boolean,
): ResultsState => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_VENUES":
      return {
        ...state,
        venues: action.payload,
      };
    case "RESET_RESULTS":
      return {
        ...initialResultsState,
      };
    default:
      return {...state};
  }
};
