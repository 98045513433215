// Colors
export const BLACK_COLOR = "#222222";
export const WHITE_COLOR = "#FFFFFF";
export const RED_COLOR = "#D5293D";
export const BROWN_COLOR = "#671C1C";
export const SECTION_COLOR = "#3B3B3B"
export const SUBSECTION_COLOR = "#515151"

export const TEXT_DARK = "#FFFFFF";
export const TEXT_DARK_LIGHT = "#909090";
export const NO_INFO = "No information available"

export const CROWD_MESSAGE = [
  'Unknown',
  'Empty. You have the whole place to yourself.',
  'A few people. You can still move around easily.',
  'Small groups. You need to walk around people.',
  'Big groups. You bump into large groups of people.',
  'Packed. There are people everywhere.',
]

export const CROWD_COLORS = ['#D0D0D0', '#53ADF6', '#83D376', '#F5D254', '#FF7D05', '#F22B29']

export const LocalStorage = {
  SAVEDPLACES: "savedPlaces",
  RECENTPLACES: "recentPlaces",
  SUBSCRIBEDPLACES: "subscribedPlaces",
  ALERTSCONFIG : "alertsConfiguration",
  PLAYERID : "playerID"
}