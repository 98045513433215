import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { formatAddress, formatDistance, removeVenueFromList, listContainVenue, calculateDistance } from "../utilities/utilities";
import { SECTION_COLOR } from "../constants";
import { WHITE_COLOR, LocalStorage } from "../constants";
import { SavedPlace } from "../types/savedplace";
import {
  makeAndHandleResultsRequestById,
} from "../utilities/serviceCalls";
import { useHistory } from "react-router-dom";
//import { useLocation } from "react-router-dom";
import { usePlaceState } from "../store/place/placeContext";
import { useResultsState } from "../store/results/resultsContext";
import HeartCheckBox from "./HeartCheckBox";
import Lockr from "lockr";
import { SavedPlacesContext } from "../contexts";

function NearbySimilarCard(props: {
  venue: SavedPlace;
  origin: {
    lat: number,
    lng: number
  };
  className?: string;
}) {
  const { venue, origin } = props;
  const history = useHistory();
  // const location = useLocation();
  // Global State
  const { dispatchPlaceState } = usePlaceState();
  const { dispatchResultsState } = useResultsState();
  const [savedPlaces, setSavedPlaces] = React.useContext(SavedPlacesContext)
  const [favorite, setFavorite] = useState(false)

  // eslint-disable-next-line
  useEffect(() => setFavorite(listContainVenue(savedPlaces, venue)))

  const executeResultsEstimation = async (venue: SavedPlace): Promise<void> => {
    let results;

    // Check if selectedVenue ID is an Address and handle appropriate results request
    results = await makeAndHandleResultsRequestById(venue.id);

    dispatchResultsState({
      type: "SET_VENUES",
      payload: results,
    });
    // replace selected venue
    dispatchPlaceState({
      type: "SET_SELECTED_VENUE",
      payload: results.venue,
    });

    // if (location.pathname === "/results" || "/savedPlaces") {
    history.push("/venue_details?venueId=" + results.venue.id +
      '&name=' + results.venue.name +
      '&lat=' + results.venue.location.lat +
      '&lng=' + results.venue.location.lng +
      '&query=' + results.venue.name
    );
    // }
  }

  const onHeartCheck = (event: any) => {
    event.stopPropagation()
    if (favorite) {
      const savedPlaces = Lockr.smembers(LocalStorage.SAVEDPLACES)
      const newSavedPlaces = removeVenueFromList(savedPlaces, venue)
      Lockr.set(LocalStorage.SAVEDPLACES, newSavedPlaces)
      setSavedPlaces(newSavedPlaces) // Update global state
    } else {
      Lockr.sadd(LocalStorage.SAVEDPLACES, venue)
      const newSavedPlaces = Lockr.smembers(LocalStorage.SAVEDPLACES)
      setSavedPlaces(newSavedPlaces) // Update global state
    }
    setFavorite(favorite ? false : true)
  }

  let distance = formatDistance(calculateDistance(origin, venue.location))
  // let imgSrc = venue.categories[0].icon.prefix
  return (
    <Card className={props.className} onClick={() => executeResultsEstimation(venue)}
      style={{ borderRadius: '0px', background: SECTION_COLOR, color: WHITE_COLOR }}>
      <Card.Body style={{ padding: '0px' }}>
        <Row>
          <Col xs={2}>
            <div style={{ margin: 'auto', width: '50px' }}>
              <img src={venue.icon}
                alt="" width='50px' height='50px' />
            </div>
          </Col>
          <Col xs={7}>
            <div>{venue.name}</div>
            <div style={{ fontSize: '10px', position: 'absolute', bottom: '0px' }}>{formatAddress(venue)}</div>
          </Col>
          <Col xs={3}>
            <div style={{ textAlign: 'right' }}>{distance}</div>
            <div style={{ textAlign: 'right' }}><HeartCheckBox checked={favorite} onClick={onHeartCheck} /></div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default NearbySimilarCard;
