import React from "react";
import GoogleMapReact from "google-map-react";
import { GMAPS_API_KEY } from "../../services/config";
import { Minivenue } from "../../types/foursquare";
import Marker from "./Marker";
import Area from "./Area";
import { CROWD_COLORS } from "../../constants";
import { style } from '../../assets/images/mapStyle/darkModeMap';

interface IMapWrapperProps {
  children?: React.ReactNode;
  venue: Minivenue;
  markers?: any;
  zoom?: number;
  height?: string;
}

function MapWrapper(props: IMapWrapperProps) {
  const { venue, markers, zoom, height } = props;
  const defaultZoom = 15;
  const defaultCenter = {
    lat: 37.360825,
    lng: -121.949902
  };
  const center = {
    lat: venue.location.lat,
    lng: venue.location.lng
  };

  const defaultHeight = height ? height : "770px";

  // console.log(markers)
  return (
    <div style={{ height: defaultHeight, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAPS_API_KEY, language: "en" }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        hoverDistance={40 / 2}
        options={{
          styles: style
        }}
      >
        {markers.map((marker: any, i: any) => {
          return (
            <Area
              key={`marker-${i}`}
              radius={50 + 20 * marker.index}
              lat={marker.lat}
              lng={marker.lng}
              color={CROWD_COLORS[marker.index]}
            />
          );
        })}
        <Marker
          key={`venue-${venue.id}`}
          title={venue.name}
          lat={venue.location.lat}
          lng={venue.location.lng}
          color="blue"
        />
      </GoogleMapReact>
    </div>
  );
}

export default MapWrapper;
