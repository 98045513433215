import axios, { AxiosResponse } from "axios";
//const url = "https://crowd-alerts.wl.r.appspot.com/"
const BASE_URL = "http://localhost:8000/"
export const registerUser = async (queryParams: {
    onePush: boolean,
    oneSound: boolean,
    fivePush: boolean,
    fiveSound: boolean,
    sync : boolean,
    playerId: string  | null | undefined,
    username: string,
  }):  Promise<AxiosResponse> => { 
    return await axios.post(`${BASE_URL}user`, 
    {
      ...queryParams,
      system: "Web",
      enabled: true
    });
  };

  export const updateUser = async (queryParams: {

    username: string,
    attr: string,
    value: boolean
  }):  Promise<AxiosResponse> => { 
    return await axios.put(`${BASE_URL}user`, 
    {
      ...queryParams,
      system: "Web"
    });
  };

  export const createAlert = async (queryParams: {
    username: string,
    venueId: string
  
  }):  Promise<AxiosResponse> => { 
    return await axios.post(`${BASE_URL}alert`, 
    {
      ...queryParams,
      system: "Web"
    });
  };

  export const deleteAlert = async (queryParams: {
    username: string,
    venueId: string
    
  }):  Promise<AxiosResponse> => { 
    return await axios.put(`${BASE_URL}alert`, 
    {
      ...queryParams,
      system: "Web"
    });
  };