import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { isEmpty as _isEmpty } from "lodash";
import { usePlaceState } from "../store/place/placeContext";
import { useResultsState } from "../store/results/resultsContext";
import { ResultsRecommendation } from "../types/api";
import { Venue } from "../types/nearby";
import  NearbySimilarCard  from "../components/NearbySimilarCard";
import { useHistory, useLocation } from "react-router-dom";
import PlaceInput from "../components/PlaceInput";
import MapWrapper from "../components/Map/MapWrapper";
import { convertGplacesToVenues } from "../utilities/utilities"
import { foursquare2saved, gplace2saved, crowdAnalytics2saved } from "../utilities/convertVenues";
import { useGeoLocation } from "../hooks/useGeolocation";
import _ from "lodash";

import {
  makeAndHandleSimilarVenueRequest,
  makeAndHandleNearbyVenueRequest
} from "../utilities/serviceCalls";
import "../styles/tabBar.css";
import { Minivenue } from "../types/foursquare";

function Results() {
  const { placeState } = usePlaceState();
  const { resultsState } = useResultsState();
  const history = useHistory();
  const location = useLocation();

  const [nearByVenues, setNearByVenues] = useState<Venue[]>();


  const [similarVenues, setSimilarVenues] = useState<Venue[]>();

  const [selectedOrigin, setSelectedOrigin] = useState({lat: 0, lng: 0});

  // eslint-disable-next-line
  const [suggestions, setSuggestions] = useState<ResultsRecommendation[]>(
    [] as ResultsRecommendation[]
  );
  const { latitude, longitude } = useGeoLocation();
  const origin = { lat: Number(latitude), lng: Number(longitude) };

  // eslint-disable-next-line
  const onSelectedVenueClick = async (e: any): Promise<void> => {
    if (location.pathname === "/results") {
      history.push("/venue_details");
    }
  }

  const executeNearbyRequest = async (
    venue: Minivenue
  ): Promise<void> => {
    const results = await makeAndHandleNearbyVenueRequest({
      radius: 100,
      latitude: venue.location.lat,
      longitude: venue.location.lng
    });
    var fetchedVenues = results.response.groups[0].items.map(groupitem => groupitem.venue);
    fetchedVenues.forEach(venue => {
      placeState.markers.push({
        name: venue.name,
        lat: venue.location.lat,
        lng: venue.location.lng
      })
    })
    setNearByVenues(fetchedVenues);
  };

  const executeSimilarRequest = async (
    venue: Minivenue
  ): Promise<void> => {
    const originLat = venue.location.lat;
    const originLng = venue.location.lng;
    const venueType = _.get(venue, "types[0]", null) || _.get(venue, "categories[0].name", null)
    const results = await makeAndHandleSimilarVenueRequest({
      id: venue.id,
      latitude: originLat,
      longitude: originLng,
      venueType: venueType
    });
    let gplaceItems: Array<any> = _.get(results, "results", []);
    let venueItems = convertGplacesToVenues(gplaceItems, originLat, originLng);
    venueItems.forEach(venue => {
      placeState.markers.push({
        name: venue.name,
        lat: venue.location.lat,
        lng: venue.location.lng
      })
    })
    setSimilarVenues(venueItems);
  };

  // Filter suggestions and nearByVenues from recommendation results
  useEffect(() => {
    if (placeState.selectedVenue.location) {
      placeState.markers.push({
        name: placeState.selectedVenue.name,
        lat: placeState.selectedVenue.location.lat,
        lng: placeState.selectedVenue.location.lng
      })
      executeNearbyRequest(placeState.selectedVenue);
      executeSimilarRequest(placeState.selectedVenue);
      setSelectedOrigin({
        lat: placeState.selectedVenue.location.lat,
        lng: placeState.selectedVenue.location.lng
      });
    }
    if (!_isEmpty(resultsState.venues)) {
      let tempSuggestions: ResultsRecommendation[] = [] as ResultsRecommendation[];

      setSuggestions(tempSuggestions);
    }
    // eslint-disable-next-line
  }, [resultsState.venues]);

  return (
    <Fragment>
      <Row className="mt-5">
        <Col xs={12} lg={6}>
          <PlaceInput buttonText ="Estimate"  height={"30px"} margin={10}/>
          {!_isEmpty(resultsState.venues) &&
            !_isEmpty(placeState.selectedVenue) && (
              <Fragment>
                <div style={{paddingBottom: "30px"}}>
                  <NearbySimilarCard
                    className="mt-1 shadow p-3"
                    venue={crowdAnalytics2saved(placeState.selectedVenue)}
                    origin={origin}
                  />
                </div>

                <Tabs className="mySavedTabClass" defaultActiveKey="similar" id="uncontrolled-tab-example">
                  <Tab eventKey="similar" title="Similar Venues" tabClassName='saved-tab'>
                    <div>
                      {!_isEmpty(similarVenues) &&
                        similarVenues?.map((item, index) => (
                          <NearbySimilarCard
                            key={item.id}
                            className="mt-1 shadow p-3"
                            venue={gplace2saved(item)}
                            origin={selectedOrigin}
                          />
                        ))}
                      {_isEmpty(similarVenues) && (
                        <div className='empty-list-section'>No similar venues to display</div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="nearby" title="Nearby Venues" tabClassName='saved-tab'>
                    <div>
                      {!_isEmpty(nearByVenues) &&
                        nearByVenues?.map((item, index) => (
                          <NearbySimilarCard
                            key={item.id}
                            className="mt-1 shadow p-3"
                            venue={foursquare2saved(item)}
                            origin={selectedOrigin}
                          />
                        ))}
                      {_isEmpty(nearByVenues) && (
                        <div className='empty-list-section'>No nearby venues to display</div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </Fragment>
            )}
        </Col>
        <Col xs={12} lg={6}>
          {!_isEmpty(placeState.selectedVenue) &&
            !_isEmpty(resultsState.venues) && (
              <MapWrapper
                venue={placeState.selectedVenue}
                markers={placeState.markers}
              />
            )}
        </Col>
      </Row>

      {/* <Row>
        <Col xs={12} md={6} className="mt-5">
          <p>Results</p>

          <pre>Written Venue: {placeState.venue}</pre>
          <pre>
            Selected Venue: {JSON.stringify(placeState.selectedVenue, null, 2)}
          </pre>
          <pre>Results: {JSON.stringify(resultsState.venues, null, 2)}</pre>
        </Col>
      </Row> */}
    </Fragment>
  );
}

export default Results;
