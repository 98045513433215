import React, { Fragment } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { isEmpty as _isEmpty } from "lodash";
import  NearbySimilarCard  from "../components/NearbySimilarCard";
import MapWrapper from "../components/Map/MapWrapper";
import {} from "../utilities/serviceCalls"
import { useGeoLocation } from "../hooks/useGeolocation";
import { SavedPlacesContext, RecentPlacesContext } from "../contexts";
import { usePlaceState } from "../store/place/placeContext";

function SavedPlaces() {
  // eslint-disable-next-line
  const [savedPlaces, setSavedPlaces] = React.useContext(SavedPlacesContext)
  // eslint-disable-next-line
  const [recentPlaces, setRecentPlaces] = React.useContext(RecentPlacesContext)
  // eslint-disable-next-line
  const { placeState, dispatchPlaceState } = usePlaceState();
  const { latitude, longitude } = useGeoLocation();
  const origin = { lat: Number(latitude), lng: Number(longitude) };
  const generateMap = () => {
    if (!_isEmpty(placeState.selectedVenue)) {
      return (
        <MapWrapper
          venue={placeState.selectedVenue}
          height="100vh"
          markers={placeState.markers}
        />
      );
    }
  };
  var recentPlacesJsx = []
  for (let i = recentPlaces.length - 1; i >= 0; i--) {
    recentPlacesJsx.push(
      <NearbySimilarCard
        key={recentPlaces[i].id}
        className="mt-1 shadow p-3"
        venue={recentPlaces[i]}
        origin={origin}
      />
    )
  }
  var savedPlacesJsx = []
  for (let i = savedPlaces.length - 1; i >= 0; i--) {
    savedPlacesJsx.push(
      <NearbySimilarCard
        key={savedPlaces[i].id}
        className="mt-1 shadow p-3"
        venue={savedPlaces[i]}
        origin={origin}
      />
    )
  }
  return (
    <Fragment>
      <Row
        style={{
          minHeight: "100vh",
          borderLeft: "solid 0.5px rgba(255,255,255,0.6)"
        }}
      >
        <Col xs={12} lg={6}>
          <Fragment>
            <Tabs className="mySavedTabClass" defaultActiveKey="saved" id="uncontrolled-tab-example">
              <Tab eventKey="saved" title="Saved Places" tabClassName='saved-tab'>
                <div>
                  {!_isEmpty(savedPlaces) && savedPlacesJsx}
                  {_isEmpty(savedPlaces) && (
                    <div className='empty-list-section' style={{ fontSize: "15px" }}>No saved places to display</div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="recent" title="Recently Visited" tabClassName='saved-tab'>
                <div>
                  {!_isEmpty(recentPlaces) && recentPlacesJsx }
                  {_isEmpty(recentPlaces) && (
                    <div className='empty-list-section' style={{ fontSize: "15px" }}>No recent places to display</div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </Fragment>
        </Col>
        <Col xs={12} lg={6}>
          {generateMap()}
        </Col>
      </Row>
    </Fragment>
  );
}

export default SavedPlaces;
